const lang: any = {
    list: {
        PT: 'Lista',
        US: 'List',
        ES: 'Lista'
    },

    of: {
        PT: ' de ',
        US: ' of ',
        ES: ' de '
    },

    inspected: {
        PT: ' inspecionados',
        US: ' inspected',
        ES: ' inspeccionados'
    },

    plant: {
        PT: 'Planta',
        US: 'Plant',
        ES: 'Planta'
    },

    process: {
        PT: 'Processo',
        US: 'Process',
        ES: 'Proceso'
    },

    equipment: {
        PT: 'Equipamento',
        US: 'Equipment',
        ES: 'Equipo'
    },

    search: {
        PT: 'Pesquisar',
        US: 'Search',
        ES: 'Buscar'
    },

    company: {
        PT: 'Empresa',
        US: 'Company',
        ES: 'Compañía'
    },

    streets: {
        PT: 'Ruas',
        US: 'Streets',
        ES: 'Calles'
    },

    satellite: {
        PT: 'Satélite',
        US: 'Satellite',
        ES: 'Satélite'
    },

    floor: {
        PT: 'Andar',
        US: 'Floor',
        ES: 'Andar'
    },

    all: {
        PT: "Todos",
        US: "All",
        ES: "Todos"
    },
    
}

export default lang;
