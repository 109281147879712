const lang: any = {
    registerClient: {
        PT: 'Cadastrar Novo Cliente',
        US: 'Register New Client',
        ES: 'Registrar Nuevo Cliente'
    },

    editClient: {
        PT: 'Editar Cliente',
        US: 'Edit Client',
        ES: 'Editar Cliente'
    },

    userInfo: {
        PT: 'INFORMAÇÕES DO USUÁRIO',
        US: 'USER INFO',
        ES: 'INFORMACIÓN DEL USUARIO'
    },

    name: {
        PT: 'Nome Completo',
        US: 'Full Name',
        ES: 'Nombre Completo'
    },

    password: {
        PT: 'Senha',
        US: 'Password',
        ES: 'Contraseña'
    },

    company: {
        PT: 'Empresa',
        US: 'Company',
        ES: 'Empresa'
    },

    category: {
        PT: 'Categoria',
        US: 'Category',
        ES: 'Categoría'
    },

    register: {
        PT: 'Cadastrar',
        US: 'Register',
        ES: 'Registrar'
    },

    edit: {
        PT: 'Editar',
        US: 'Edit',
        ES: 'Editar'
    },

    addNew: {
        PT: 'Adicionar Novo',
        US: 'Add New',
        ES: 'Agregar Nuevo'
    },

    manager: {
        PT: 'Gerente',
        US: 'Manager',
        ES: 'Gerente'
    },

    auxiliary: {
        PT: 'Auxiliar',
        US: 'Auxiliary',
        ES: 'Auxiliar'
    },

    admin: {
        PT: 'Administrador',
        US: 'Administrator',
        ES: 'Administrador'
    },

    invalidEmail: {
        PT: "Email Inválido!",
        US: "Invalid Email!",
        ES: "¡Correo Electrónico Inválido!"
    },
    
}

export default lang;
