//import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faFolder, faFileLines, faClock, faChevronRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import './styles.css';

import FlagSelector from "../../components/FlagSelector";
import Sidebar from '../../components/Sidebar';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { deleteJsonData, getStoredJson, saveJsonData, sleep, useInitialCheck } from '../../utils';
import { getDataBaseCondition, getDatabaseRows } from '../../utils/database';
import { formatTimestamp, sortArrayDatetime } from '../../components/DatePicker';
import { useGlobalContext } from '../../contexts/GlobalContext';
import lang from './lang';
import LoadingDiv from '../../components/LoadingDiv';
import ScrollView from '../../components/ScrollView';


export function HomePage() {
	const [inspections, setInspections] = useState<any>([])
	const [myRequests, setMyRequests] = useState<any>([])
	const [inspectorSync, setInspectorSync] = useState<any>([])
	const { globalOptions, setGlobalOptions } = useGlobalContext()
	const [update, setUpdate] = useState<boolean>(true)
	const currLang = globalOptions.language
	const user = getStoredJson('user')

	const navigate = useNavigate();
	useInitialCheck(importInfo)
	useInitialCheck(importSync, [inspections])

	async function importInfo() {
		await sleep(500)
		let dbInspections = await getDatabaseRows('inspections')
		let dbRequests    = await getDataBaseCondition('inspections', 'requested_by', getStoredJson('user').id)

		dbInspections = dbInspections.filter((item: any) => item.is_checked == 1)

		if (user.company != '123456789/0001')
			dbInspections = dbInspections.filter((item: any) => item.company == user.company)

		dbInspections = sortArrayDatetime(dbInspections, 'last_modified_at')
		dbRequests = sortArrayDatetime(dbRequests, 'last_modified_at')

		setInspections(dbInspections)
		setMyRequests(dbRequests)
		setUpdate(false)
	}

	function isValueInArray(array: any, desiredKey: string, desiredValue: any) {
		for (let x = 0; x < array.length; x++)
			if (array[x][desiredKey] && array[x][desiredKey] == desiredValue)
				return x

		return -1
	}

	async function importSync() {
		let sync = []

		for (let x = 0; x < inspections.length; x++) {
			let inspection = inspections[x]
			let foundIndex = isValueInArray(sync, 'inspector', inspection.inspector)

			if (foundIndex == -1) {
				sync.push(inspection)
				continue
			}

			let date_inspection = new Date(inspection.last_modified_at)
			let date_found = new Date(sync[foundIndex].last_modified_at)

			if (date_found < date_inspection)
				sync[foundIndex] = inspection
		}

		setInspectorSync(sync)
	}

	function renderRequest(item: any, index: number) {
		const relatory = item.relatory ? JSON.parse(item.relatory) : {}
		const isEdit = Object.keys(relatory).length == 0

		return isEdit && (
			<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
				<div className='item_Home' key={index}>
					<p className="date_Home" style={{fontSize: 'calc(5px + .5vw)'}}>{item.title}</p>

					<div style={{ display: 'flex', alignItems: 'center' }}>

						<button className="arrow_Home" onClick={() => handleNextPage(item)}>
							<FontAwesomeIcon icon={faChevronRight} className="iconarrow_Home" />
						</button>
					</div>
				</div>

				<div className="line_Home" />
			</div>
		)
	}

	function handleNextPage(item: any) {
		saveJsonData('pendingInspection', item)
		return navigate('/Explore')
	}

	function renderLastInspection(item: any) {
		const relatory = item.relatory ? JSON.parse(item.relatory) : {}
		const isEdit = Object.keys(relatory).length == 0

		return !isEdit && (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<div className="item_Home">
					<span className="date_Home" style={{fontSize: 'calc(5px + .5vw)'}}>{item.title}</span>

					<div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'center' }}>
						<FontAwesomeIcon 
							icon={item.approved == 1 ? faCheck : faClock} 
							className="iconinline1_Home" 
							title={item.approved == 1 ? 'OK' : "Pendente aprovação"} 
						/>

						<button 
							className="arrow_Home" 
							onClick={() => handleNextPage(item)}
						>
							<FontAwesomeIcon icon={faChevronRight} className="iconarrow_Home" />
						</button>
					</div>
				</div>

				<div style={{ padding: '.33rem' }} />
				<div className="line_Home" />
				<div style={{ padding: '.33rem' }} />
			</div>
		)
	}

	function renderSync() {
		console.log(JSON.stringify(inspectorSync))
		return inspectorSync.map((item: any) => (
			<div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
				<div className="item_Home">
					<span className="date_Home" style={{fontSize: 'calc(5px + .5vw)'}}>{item.inspector}</span>

					<span className="sincro_Home" style={{ width: '50%', textAlign: 'end', fontSize: 'calc(5px + .5vw)' }}>
						{lang.syncedTo[currLang]} {item.last_modified_at ? formatTimestamp(item.last_modified_at) : 'nenhuma'}
					</span>
				</div>

				<div style={{ padding: '.2rem' }} />
				<div className="line_Home" />
				<div style={{ padding: '.2rem' }} />
			</div>
		))
	}

	function renderFirstBlock() {
		return (
			<div className="upperSide_Home">
				<ScrollView className="block_Home">
					<div className="title_Home">{lang.lastInspections[currLang]}</div>

					<div style={{ padding: '.5rem' }} />

					<LoadingDiv loading={update} className="inspectorList_Home">
						{inspections.map((item: any) => renderLastInspection(item))}
					</LoadingDiv>
				</ScrollView>

				<div className="centralBlock_Home">
					<button className="centralButton_Home" onClick={() => navigate("/Reports?base")}>
						<FontAwesomeIcon icon={faFolder} className="icon_Home" />

						<div className="button-text">
							{lang.reports[currLang]}
						</div>
					</button>

					<button className="centralButton_Home" onClick={() => navigate('/Request')}>
						<FontAwesomeIcon icon={faFileLines} className="icon_Home" />

						<div className="button-text">
							{lang.newInspection[currLang]}
						</div>
					</button>

					<button className="centralButton_Home" onClick={() => navigate('/Calendar')} disabled={false}>
						<FontAwesomeIcon icon={faCalendar} className="icon_Home" />

						<div className="button-text">
							{lang.inspectionCalendar[currLang]}
						</div>
					</button>
				</div>

				<ScrollView className="block_Home">
					<div className="title_Home">{lang.requestedInspection[currLang]}</div>

					{update && <div style={{ padding: '.5rem' }} />}

					<LoadingDiv loading={update} className="inspectorList_Home" style={{fontSize: 'calc(5px + .5vw)'}}>
						{myRequests.map((item: any, index: number) => renderRequest(item, index))}
					</LoadingDiv>
				</ScrollView>
			</div>
		)
	}
	
	function renderSecondBlock() {
		return (
			<div className='lowerSide_Home'>
				<div className="title_Home">{lang.inspSync[currLang]}</div>
				<div style={{ padding: '.5rem' }} />

				<LoadingDiv loading={update} className="inspectorList_Home">
					{renderSync()}
				</LoadingDiv>
			</div>
		)
	}

	return (
		<div className="MainContainer">
			<FlagSelector />
			<Sidebar activePage='Home' />

			<div className='SideBarCompensation' />

			<div className='CentralBlock'>
				<div className="content_Home">
					<div className='mainHomeContainer'>
						{renderFirstBlock()}

						<div style={{ padding: '2vh' }} />

						{renderSecondBlock()}
					</div>
				</div>
			</div>
		</div>
	);
}