import { useEffect, useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FlagSelector from "../../../../../components/FlagSelector";
import CustomModal, { CustomModalPage } from "../../../../../components/Modal";
import Sidebar from "../../../../../components/Sidebar";
import { faAngleLeft, faCheckSquare, faFolder, faSquare, faCopy, faCheck } from "@fortawesome/free-solid-svg-icons";
import AsyncButton from "../../../../../components/AsyncButton";
import mapboxgl from "mapbox-gl";
import { getStoredJson, randomNumericString, saveJsonData, sleep } from "../../../../../utils";
import { addDataBaseRow, editDatabaseByCondition, genUUID, getDataBaseCondition, postApiData, sendFileDatabase, sendImageDatabase, url } from "../../../../../utils/database";
import lang from '../../lang'
import { useGlobalContext } from "../../../../../contexts/GlobalContext";
import CustomTextField from "../../../../../components/CustomTextField";
import validator from "validator";

type ThirdPageProps = {
    selectedEquipment: any;
    technicalData: any;
    edit: boolean;
    modalOpen: boolean;
    setPage: (pageNum: number) => void;
    numFloors: number;
    navigate: any;
    imageData: any;
    setImageData: (img: any) => void;
	fileData: any;
    setFileData: (doc: any) => void;
    setNumFloors: (floor: number) => void;
    handleManagement: () => void;
    setModalOpen: (modalOpen: boolean) => void;
    page: number;
}

export function ThirdPage({ 
    technicalData, 
    edit, 
    modalOpen,
    selectedEquipment,
    setPage,
    numFloors,
    navigate,
    imageData,
    setImageData,
	fileData,
    setFileData,
    setNumFloors,
    handleManagement,
    setModalOpen,
    page
}: ThirdPageProps) {
    const [mapStyle, setMapStyle] = useState<string>(localStorage.getItem('mapStyle') || "mapbox://styles/mapbox/streets-v12");
	const mapContainer = useRef<HTMLDivElement | null>(null);
	const [map, setMap] = useState<mapboxgl.Map | null>(null);
	const [marker, setMarker] = useState<mapboxgl.Marker | null>(null);
	const [markerCoords, setMarkerCoords] = useState({ lng: 0, lat: 0 }); // Novo estado para coordenadas do marcador
	const [capturedImageUrl, setCapturedImageUrl] = useState("");
	const [showMap, setShowMap] = useState(true); // Estado inicial para mostrar o mapa
    
	const [multipleLayers, setMultipleLayers] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    //const [canGoToNextPage, setCanGoToNextPage] = useState(false)

    const [expectedCode, setExpectedCode] = useState<any>({code: '', timestamp: null})
    const [nextModalOpen, setNextModalOpen] = useState(false)
    const [emailModalOpen, setEmailModalOpen] = useState(false)
    const [email, setEmail] = useState<string>('')
    const [emailMessage, setEmailMessage] = useState<string>('')
	const [emailWarning, setEmailWarning] = useState(false)
    const [formLink, setFormLink] = useState<string>('')
    const [formInfo, setFormInfo] = useState<any>({})
    const [copy, setCopy] = useState(false)

    const { globalOptions } = useGlobalContext()
    const currLang = globalOptions.language
    
    function isMarkerSet() {
		if (!marker)
			return false
		return true
	}

    // useEffect(() => {
    //     setCanGoToNextPage(Object.values(selectedEquipment).every(val => isValid(val)))
    //  }, [selectedEquipment])

    useEffect(() => {
		localStorage.setItem('mapStyle', mapStyle);
	}, [mapStyle]);

    useEffect(() => {
		function initializeMap() {

			const map = new mapboxgl.Map({
				container: mapContainer.current!, // Especifica o elemento DOM para inicializar o mapa
				style: mapStyle, // O estilo do mapa
				center: [selectedEquipment.process.long, selectedEquipment.process.lat], // Coordenadas iniciais do centro do mapa
				zoom: 18, // Zoom inicial
			});

			map.addControl(new mapboxgl.NavigationControl(), 'top-right');

			map.on('load', () => {
				setMap(map);
			});
			let newMarker: any = null
			// Adiciona o evento de clique para adicionar marcadores
			map.on('click', function (e) {

				if (!newMarker) {
					newMarker = new mapboxgl.Marker({ draggable: false, color: 'blue' })
						.setLngLat([e.lngLat.lng, e.lngLat.lat])
						.addTo(map)
					newMarker.getElement().addEventListener('click', (event: any) => {
						event.stopPropagation();
						newMarker.remove();
						newMarker = null
						setMarkerCoords({ lat: 0, lng: 0 })
						setMarker(null);
					});

					setMarkerCoords({ lat: e.lngLat.lat, lng: e.lngLat.lng })
					setMarker(newMarker);
					console.log(marker)

				}
			}
			);

			return () => map.remove(); // Remove o mapa ao desmontar o componente
		}

		if (!map)
			initializeMap();
	}, [map, marker, page]);

    function handleBack() {
		setPage(2)
		setMap(null)
		setShowMap(true)
	}

    function isValid(value: any) {
		return value && value !== undefined && value !== ''
	}

    function handleCopy() {
		setCopy(true)
		navigator.clipboard.writeText(formLink)
	}

  function isModalFieldsEmpty() {
		if (!email){
			console.log("n tem email");
			return true
		}
		for (let value of email) {
			if (!value || value.trim().length === 0) {
				console.log("Campo de email vazio ou contém apenas espaços em branco");
				return true;
			}
		}
	
		return false;
	}

    const handleOpenNextModal = () => {
		setEmailModalOpen(false)
		setNextModalOpen(true)
	};

	const handleEmailChange = (newEmail: any) => {
		setEmail(newEmail);  // Atualiza o estado do email com o novo valor
		setEmailWarning(false);  // Remove o aviso
	};

    async function handleSendMail(){
		if(!validator.isEmail(email))
			return setEmailWarning(true)

		setLoading(true)
		await sleep(1000)
		
		let msg

		if (emailMessage != lang.emailMsg[currLang])
			msg = emailMessage
		else
			msg = lang.emailMsg[currLang]
		

		const newCode = randomNumericString(4)
		setExpectedCode({code: newCode, timestamp: new Date()})

		const data = {
			destinyMail: email.trim(),
			subject: lang.formFillingRequest[currLang],
			message: msg + '\n\n' + formLink
		}

		const response = await postApiData(data, 'sendMail')

		if(!response || response.status != 'success'){
			await handleSendMail()
			return
		}

		handleOpenNextModal()

		//setAlertTitle(lang.min[currLang])
		setLoading(false)
	}


    async function handleEdit() {
		const edit = getStoredJson('edit')

		const newData = {
			equipment_id: edit.equipment_id,
			name: selectedEquipment.name,
			company: selectedEquipment.company.cnpj,
			plant: selectedEquipment.plant.plant_id,
			process: selectedEquipment.process.process_id,
			img_path: selectedEquipment.img_path,
			doc_path: selectedEquipment.doc_path,
			equipment_type: selectedEquipment.equipment_type.id,
            technical_data: JSON.stringify(technicalData),
			lat: markerCoords.lat,
			long: markerCoords.lng,
			floor: numFloors
		}

		const response = await editDatabaseByCondition('equipments', 'equipment_id', edit.equipment_id, newData)

		if (response.status != 'success')
			return alert('erro ao editar no servidor')

		for (let file of fileData)
			await sendFileDatabase(file, newData.doc_path)

		for (let x = 0; x < imageData.length; x++) {
			const file = imageData[x]
			await sendImageDatabase(file, newData.equipment_id, 'equipment', x)
		}

		navigate(-1)
	}

	async function handleSubmit() {
		const uuid = genUUID()

		const equipmentData = {
			equipment_id: uuid,
			name: selectedEquipment.name,
			company: selectedEquipment.company.cnpj,
			equipment_type: selectedEquipment.equipment_type.id,
			plant: selectedEquipment.plant.plant_id,
			process: selectedEquipment.process.process_id,
			equipmentImage: selectedEquipment.equipmentImage,
			technical_data: JSON.stringify(technicalData),
			img_path: `img/equipment/${uuid}`,
			doc_path: `doc/equipment/${uuid}`,
			lat: markerCoords.lat,
			long: markerCoords.lng,
			floor: numFloors
		}

		const formData = {
			company: equipmentData.company,
			asset_id: equipmentData.equipment_id
		}

		saveJsonData('insert', { ...equipmentData, equipment: equipmentData.equipment_id })
		const response = await addDataBaseRow('equipments', equipmentData)
        const response2 = await addDataBaseRow('forms', formData)

		if (response.status != 'success' || response2.status != 'success')
                return alert('erro ao adicionar')

		for (let file of fileData)
			await sendFileDatabase(file, equipmentData.doc_path)

		for (let x = 0; x < imageData.length; x++) {
			const file = imageData[x]
			if (imageData[x].name == 'locality.png') {
				await sendImageDatabase(file, uuid, 'equipment', x, 'locality')
			}
			else {
				await sendImageDatabase(file, uuid, 'equipment', x)
			}
		}

        await sleep(200)

        let emailMsg = lang.emailMsg[currLang]
        setEmailMessage(emailMsg)
        let formLink = url.replace('api/?', '') + 'Forms?equipment-' + uuid

        setFormLink(String(formLink))
        setEmailModalOpen(true)
	}

    async function downloadImage(url: RequestInfo | URL, filename: string) {
		try {
			const response = await fetch(url);
			if (!response.ok) throw new Error('Falha ao baixar a imagem.');
			const imageBlob = await response.blob(); // Obtém a resposta como Blob
			const imageFile = new File([imageBlob], filename, { type: imageBlob.type }); // Converte Blob para File
			return imageFile;
		} catch (error) {
			console.error('Erro ao baixar a imagem:', error);
			return null;
		}
	}

    async function captureMap() {
		let url
		if (mapStyle == "mapbox://styles/mapbox/streets-v12")
			url = `https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/geojson(%7B%22type%22%3A%22FeatureCollection%22%2C%22features%22%3A%5B%7B%22type%22%3A%22Feature%22%2C%22properties%22%3A%7B%22marker-color%22%3A%22%230000FF%22%7D%2C%22geometry%22%3A%7B%22type%22%3A%22Point%22%2C%22coordinates%22%3A%5B${markerCoords.lng}%2C${markerCoords.lat}%5D%7D%7D%5D%7D)/${markerCoords.lng},${markerCoords.lat},18/500x300?access_token=pk.eyJ1IjoiamVhbnZyMDk4IiwiYSI6ImNscGQyc2hnejBwaTcya21tY2w3emJpOHAifQ.Aa96RubapYbo0gdZbBUxjw`;
		else if (mapStyle == "mapbox://styles/mapbox/satellite-streets-v12")
			url = `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/static/geojson(%7B%22type%22%3A%22FeatureCollection%22%2C%22features%22%3A%5B%7B%22type%22%3A%22Feature%22%2C%22properties%22%3A%7B%22marker-color%22%3A%22%230000FF%22%7D%2C%22geometry%22%3A%7B%22type%22%3A%22Point%22%2C%22coordinates%22%3A%5B${markerCoords.lng}%2C${markerCoords.lat}%5D%7D%7D%5D%7D)/${markerCoords.lng},${markerCoords.lat},18/500x300?access_token=pk.eyJ1IjoiamVhbnZyMDk4IiwiYSI6ImNscGQyc2hnejBwaTcya21tY2w3emJpOHAifQ.Aa96RubapYbo0gdZbBUxjw`;

		if (url) {
			setCapturedImageUrl(url);
			setShowMap(false);

			const filename = "locality.png"; // Defina um nome de arquivo apropriado

			downloadImage(url, filename).then(imageFile => {
				if (imageFile) {
					console.log(imageFile)
					setImageData([...imageData, imageFile])

				}
			});
		}
	}
    
    return (
        <div className="MainContainer">
            <FlagSelector />
            <Sidebar activePage='Register' />

            <CustomModal modalOpen={emailModalOpen} className='emailModalContainer'>
				<div className='textsModalContainer'>
					<h1 className='PageModalTextContainer'>{lang.formRegister[currLang]}</h1>
				</div>
				<div className='textsModalContainer'>
					<p className='PageModalTextContainer'>{lang.formMessage[currLang]}</p>
				</div>
				<div className='modalInputText'>
					<CustomTextField
						placeholder={lang.email[currLang]}
						value={email}
						setValue={handleEmailChange}
					/>
				</div>
				{emailWarning === true && <p style={{ color: 'red'}}>{lang.invalidEmail[currLang]}</p>}
				<div className='modalInputText'>	
					<CustomTextField
						placeholder={lang.message[currLang]}
						value={emailMessage}
						setValue={setEmailMessage}
					/>
				</div>
				<div className='modalInputText'>
					<CustomTextField
						placeholder={'Link'}
						value={formLink}
						disabled={true}
						//navigator.clipboard.writeText(selectedPlant.name)
					/>
					<FontAwesomeIcon 
						className={copy ? 'copyIconModalDisabled' : 'copyIconModal'}
						icon={copy ? faCheck : faCopy} 
						title="Copiar para área de transferência" 
						onClick={() => copy ? undefined : handleCopy()} 
					/>
				</div>
				
				<div className='modalButtonWrapper'>
					<AsyncButton
						active={loading || isModalFieldsEmpty()}
						text={lang.send[currLang]}
						className='PageModalButtonContainer'
						onClick={handleSendMail}
						loading={loading}
					/>

					<AsyncButton
						active={loading}
						text={lang.close[currLang]}
						className='PageModalButtonContainer'
						onClick={handleOpenNextModal}
						loading={loading}
					/>
				</div>
			</CustomModal>
            <CustomModalPage
                title={lang.equipmentRegistered[currLang]}
                subtitle={lang.wantToRequestInspection[currLang]}
                yesPage='/Request?insert'
                noPage='/Home'
				yesText={lang.yes[currLang]}
				noText={lang.no[currLang]}
                modal={nextModalOpen}
            />

            <div className='SideBarCompensation' />

            <div className='CentralBlock'>
                <div className="EquipmentMainContainer">

                    <div className="iconContainer" style={{ width: '100%' }}>
                        <div
                            className='iconLeft'
                            style={{ alignSelf: 'center', width: '7%' }}
                            onClick={handleBack}
                        >

                            <FontAwesomeIcon icon={faAngleLeft} title="Empresas já cadastrada" />
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '85%' }}>
                            <p className='clientText'>
                                {edit ? lang.editEquipment[currLang] : lang.registerEquipment[currLang]}
                            </p>

                            <div className='folderContainer' onClick={handleManagement}>
                                <FontAwesomeIcon icon={faFolder} title="Equipamentos já cadastrados" />
                            </div>
                        </div>
                    </div>

                    <div style={{ width: '85%' }}>

                        {showMap ? (
                            <div style={{ width: '100%', alignContent: 'center' }}>
                                <div style={{ width: '85%' }}>
                                    <text className='equipmentText2'>{lang.equipmentCapture[currLang]}</text>
                                </div>
                                <div style={{ padding: '.7rem' }} />
                                <div ref={mapContainer} style={{ width: '100%', height: '300px' }} />

                                <div style={{ padding: '.6rem' }} />

                                <div style={{ display: 'flex', width: '100%', height: '2rem', alignItems: "center" }}>
                                    <FontAwesomeIcon
                                        icon={multipleLayers ? faCheckSquare : faSquare}
                                        style={{ color: 'white', height: '70%', cursor: 'pointer' }}
                                        onClick={() => {
                                            if (multipleLayers)
                                                setNumFloors(1)

                                            setMultipleLayers(prev => !prev)
                                        }}
                                    />

                                    <div style={{ padding: '5px' }} />

                                    <div style={{ color: 'white' }}>
                                        {lang.selectAFloor[currLang]}
                                    </div>

                                    <div style={{ width: '3%' }} />

                                    <input
                                        type="number"
                                        value={numFloors}
                                        className='inputFloor'
                                        disabled={!multipleLayers}
                                        onChange={(event: any) => {
                                            const text = event.target.value
                                            const num = Number(text)

                                            if (isNaN(num))
                                                return

                                            if (num < 1)
                                                return setNumFloors(1)

                                            if (num > 3)
                                                return setNumFloors(3)

                                            setNumFloors(num)
                                        }}
                                    />
                                </div>

                                <button
                                    onClick={captureMap}
                                    className='button-process'
                                    style={isMarkerSet() ? { width: '100%' } : { opacity: 0.8, cursor: 'not-allowed', width: '100%' }}
                                    disabled={!isMarkerSet()}
                                >
                                    {lang.captureMap[currLang]}
                                </button>
                            </div>

                        ) : (

                            <div style={{ width: '100%' }}>
                                <div style={{ width: '85%' }}>
                                    <text className='equipmentText2'>{lang.equipmentImage[currLang]}</text>
                                </div>
                                <div style={{ padding: '.7rem' }} />
                                <img src={capturedImageUrl} alt="Mapa Capturado" style={{ width: '100%', height: '100%' }} />
                                <div style={{ padding: '.6rem' }} />
                                <AsyncButton
                                    active={loading}
                                    text={edit ? lang.edit[currLang] : lang.register[currLang]}
                                    className='button-equipment-new'
                                    onClick={
                                        edit
                                            ?
                                            async () => { setLoading(true); await handleEdit(); setLoading(false) }
                                            :
                                            async () => { setLoading(true); await handleSubmit(); setLoading(false) }
                                    }
                                    loading={loading}
                                />
                            </div>

                        )}
                    </div>
                </div>
            </div>

        </div>
    );
}