import { useNavigate } from 'react-router-dom'
import './styles.css'

export default function CustomModal({children, modalOpen, className='', interfere=true}: any){
    return (
        <div className={modalOpen ? 'ModalContainerOpen' : 'ModalContainerClosed'}>
            <div 
                className={interfere ? ('Modal' + ' ' + className).trim() : className}
            >   
                {children}
            </div>
        </div>
    )
}


export function CustomModalPage({title, subtitle, yesPage, noPage, yesText, noText, modal}: any){
    const navigate = useNavigate()

    function modalNextPage(nextPage: any=false) {
        if(!nextPage)
            return

        navigate(nextPage)
    }

    return (
        <CustomModal modalOpen={modal} className='PageModal'>
        <p className='PageModalText'>{title}</p>

        <p className='PageModalText'>{subtitle}</p>
        
        <div style={{padding: '.3rem'}}/>

        <div style={{display: 'flex', justifyContent: 'space-between', width: '15rem'}}>
            <div className='PageModalButton' onClick={() => modalNextPage(yesPage)} style={{cursor: 'pointer'}}>
            <p>{yesText}</p>
            </div>

            <div className='PageModalButton' onClick={() => modalNextPage(noPage)} style={{cursor: 'pointer'}}>
            <p>{noText}</p>
            </div>
        </div>
        </CustomModal>
    )
}