import { useNavigate } from 'react-router-dom';
import { faAngleLeft, faFolder, faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';

import './styles.css';

import Sidebar from '../../../components/Sidebar';
import FlagSelector from '../../../components/FlagSelector';
import FileUpload from '../../../components/FileUpload';
import { SearchBox } from '../../../components/SearchBox';
import { getStoredJson, randomNumericString, saveJsonData, sleep, useInitialCheck } from '../../../utils';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { addDataBaseRow, editDatabaseByCondition, editDatabaseRowsByColumn, genUUID, getDataBaseCondition, getDatabaseRows, getImagePaths, postApiData, sendFileDatabase, sendImageDatabase, url } from '../../../utils/database';
import { CustomModalPage } from '../../../components/Modal';
import { FileDocumentUpload } from '../../../components/FileUploadDocument';
import CustomDropBox from '../../../components/CustomDropBox';
import CustomTextField from '../../../components/CustomTextField';
import lang from './lang';
import AsyncButton from '../../../components/AsyncButton';
import CustomModal from '../../../components/Modal';
import validator from 'validator';

mapboxgl.accessToken = "pk.eyJ1Ijoia2xhdXNzbWFyY2hpIiwiYSI6ImNsbGNsb245dzAyNXkzbm9iZzJ4emt2eWsifQ.HznsI_vVJ-lWe9swvbfT-Q";


export function PlantPage() {
	const [selectedPlant, setSelectedPlant] = useState<any>({})
	const [imageData, setImageData] = useState<any>([])
	const [fileData, setFileData] = useState<any>([])
	const [formInfo, setFormInfo] = useState<any>({})
	const [loading, setLoading] = useState<boolean>(false)
	const [expectedCode, setExpectedCode] = useState<any>({code: '', timestamp: null})
	const [email, setEmail] = useState<string>('')
	const [emailMessage, setEmailMessage] = useState<string>('')
	const [formLink, setFormLink] = useState<string>('')
	const [page, setPage] = useState(1);

	const mapContainer = useRef<HTMLDivElement | null>(null);
	const [mapStyle, setMapStyle] = useState<string>(localStorage.getItem('mapStyle') || "mapbox://styles/mapbox/streets-v12");
	const [map, setMap] = useState<mapboxgl.Map | null>(null);
	const [marker, setMarker] = useState<mapboxgl.Marker | null>(null);
	const [showMap, setShowMap] = useState(true); 
	const [markerCoords, setMarkerCoords] = useState({ lng: 0, lat: 0 });
	const [capturedImageUrl, setCapturedImageUrl] = useState("");

	const navigate = useNavigate();
	const { globalOptions, setGlobalOptions } = useGlobalContext()
	const currLang = globalOptions.language

	const [edit, setEdit] = useState(false)
	const [copy, setCopy] = useState(false)
	const [companies, setCompanies] = useState([])
	const [nextModalOpen, setNextModalOpen] = useState(false)
	const [emailModalOpen, setEmailModalOpen] = useState(false)
	const [emailWarning, setEmailWarning] = useState(false)
	const user = getStoredJson('user')

	useInitialCheck(initialCheck, [window.location.href])
	useInitialCheck(importCompanies)

	useEffect(() => {
		localStorage.setItem('mapStyle', mapStyle);
	  }, [mapStyle]);

	async function importCompanies() {
		let data = []

		if(user.company == '123456789/0001')
			data = await getDatabaseRows('companies')
		else
			data = await getDataBaseCondition('companies', 'cnpj', user.company)

		setCompanies(data)
	}
	
	async function initialCheck() {
		const currentUrl: string = window.location.href
		const isEdit: boolean = currentUrl.indexOf('?edit') != -1
		const isInsert: boolean = currentUrl.indexOf('?insert') != -1
		const isModal: boolean = currentUrl.indexOf('?modal') != -1
		setEdit(isEdit)
		let response, data

		if (isModal)
			handleOpenNextModal()

		if (!isEdit && !isInsert)
			return

		data = getStoredJson(isEdit ? 'edit' : 'insert')

		if (data.company) {
			response = await getDataBaseCondition('companies', 'cnpj', data.company)
			data.company = response[0]
		}

		setImageData(await getImagePaths(data.img_path))
		//setFileData(await getImagePaths(data.doc_path))
		setSelectedPlant(data)

	}

	useEffect(() => {

	}, [emailModalOpen])

	async function handleEdit() {
		const edit = getStoredJson('edit')

		const newData = {
			plant_id: edit.plant_id,
			name: selectedPlant.name,
			location: selectedPlant.location,
			company: selectedPlant.company.cnpj,
			lat: markerCoords.lat,
			long: markerCoords.lng,
			img_path: `img/plant/${selectedPlant.plant_id}`,
			doc_path: `doc/plant/${selectedPlant.plant_id}`
		}

		let response = await editDatabaseByCondition('plants', 'plant_id', edit.plant_id, newData)

		if (response.status != 'success')
			return alert('erro ao se conectar com o servidor')

		if (edit.company != newData.company) {
			response = await editDatabaseRowsByColumn('processes', 'company', edit.company, newData.company)
			response = await editDatabaseRowsByColumn('equipments', 'company', edit.company, newData.company)
			response = await editDatabaseRowsByColumn('inspections', 'company', edit.company, newData.company)
		}

		for (let file of fileData)
			await sendFileDatabase(file, newData.doc_path)

		for (let x=0; x<imageData.length; x++){
			const file = imageData[x]
			await sendImageDatabase(file, newData.plant_id, 'plant', x)
		}

		navigate(-1)
	}

	function handleNameChange(text: string) {
		setSelectedPlant({ ...selectedPlant, name: text })
	}

	function handleCompanyChange(selectedOption: any) {
		if (selectedOption.key && selectedOption.key == 'default')
			return navigate('/Company?insert')

		setSelectedPlant({ ...selectedPlant, company: selectedOption })
	}

	async function handleSendMail(){
		if(!validator.isEmail(email))
			return setEmailWarning(true)

		setLoading(true)
		await sleep(1000)
		
		let msg

		if (emailMessage != lang.emailMsg[currLang])
			msg = emailMessage
		else
			msg = lang.emailMsg[currLang]
		

		const newCode = randomNumericString(4)
		setExpectedCode({code: newCode, timestamp: new Date()})

		const data = {
			destinyMail: email.trim(),
			subject: lang.formFillingRequest[currLang],
			message: msg + '\n\n' + formLink
		}

		const response = await postApiData(data, 'sendMail')

		if(!response || response.status != 'success'){
			await handleSendMail()
			return
		}

		handleOpenNextModal()

		//setAlertTitle(lang.min[currLang])
		setLoading(false)
	}

	useEffect(() => {
        if (formInfo && formInfo.id) {
            setFormLink(formInfo.id);
        }
    }, [formInfo]); 

	async function handleSubmit() {
		const uuid = genUUID()

		const plantData = {
			plant_id: uuid,
			name: selectedPlant.name,
			location: selectedPlant.location,
			company: selectedPlant.company.cnpj,
			lat: markerCoords.lat,
			long: markerCoords.lng,
			img_path: `img/plant/${uuid}`,
			doc_path: `doc/plant/${uuid}`
		}

		const formData = {
			company: plantData.company,
			asset_id: plantData.plant_id
		}
		
		const response = await addDataBaseRow('plants', plantData)
		const response2 = await addDataBaseRow('forms', formData)

		if (response.status != 'success' || response2.status != 'success')
			return alert('erro ao adicionar')

		for (let file of fileData)
			await sendFileDatabase(file, plantData.doc_path)
		
		for (let x=0; x<imageData.length; x++){
			const file = imageData[x]
      
			if(imageData[x].name == 'locality.png'){
				await sendImageDatabase(file, uuid, 'plant', x, 'locality')
			}
					else{
				await sendImageDatabase(file, uuid, 'plant', x)
			}  
		}
		
		saveJsonData('insert', {plant: plantData.plant_id, company: plantData.company})
		
		await sleep(200)

		let emailMsg = lang.emailMsg[currLang]
		setEmailMessage(emailMsg)
		let formLink = url.replace('api/?', '') + 'Forms?plant-' + uuid

		setFormLink(String(formLink))
		setEmailModalOpen(true)
	};

	const handleOpenNextModal = () => {
		setEmailModalOpen(false)
		setNextModalOpen(true)
	  };

	function handleManagement() {
		setGlobalOptions({ ...globalOptions, activeButton: 'Plant' })
		navigate('/Managment')
	}

	function handleCopy() {
		setCopy(true)
		navigator.clipboard.writeText(formLink)
	}

	const handleEmailChange = (newEmail: any) => {
		setEmail(newEmail);  // Atualiza o estado do email com o novo valor
		setEmailWarning(false);  // Remove o aviso
	};

	function isFieldsEmpty() {
		const data = [
			selectedPlant?.name,
			selectedPlant?.company?.name,
			selectedPlant?.location
		]

		for (let value of data) {
			if (!value)
				return true

			if (value.trim().length == 0)
				return true
		}

		return false
	}

	function isModalFieldsEmpty() {
		if (!email)
			return true

		for (let value of email) {
			if (!value || value.trim().length === 0) 
				return true;
		}
	
		return false;
	}

	function handleBack (){
		setPage(1)
		setMap(null)
		setShowMap(true)
		setMarker(null)
	};

	function isMarkerSet(){
		//alert(marker)
		if(!marker)
			return false
		return true
	  }
	
	  useEffect(() => {
	
		if(page == 2){
		const initializeMap = () => {
	
		  const map = new mapboxgl.Map({
			container: mapContainer.current!, // Especifica o elemento DOM para inicializar o mapa
			//style: 'mapbox://styles/mapbox/streets-v12', // O estilo do mapa
			style: mapStyle,
			center: [ selectedPlant.long, selectedPlant.lat], // Coordenadas iniciais do centro do mapa
			zoom: 15, // Zoom inicial
		  });
		
		
		  map.addControl(new mapboxgl.NavigationControl(), 'top-right');
		  
		  map.on('load', () => {
			setMap(map);
		});
		let newMarker: any = null
		//alert(marker)
		// Adiciona o evento de clique para adicionar marcadores
		map.on('click', function (e) {
		  if(!newMarker) {
			newMarker = new mapboxgl.Marker({ draggable: false, color: 'red' })
			  .setLngLat([e.lngLat.lng, e.lngLat.lat])
			  .addTo(map)
			newMarker.getElement().addEventListener('click', (event: any) => {
			  event.stopPropagation(); // Impede que o mapa capture este clique
			  newMarker.remove();
			  newMarker = null
			  setMarkerCoords({lat: 0, lng: 0})
			  setMarker(null); // Reseta o estado do marcador
			});
			setMarkerCoords({lat: e.lngLat.lat, lng: e.lngLat.lng})
			setMarker(newMarker); 
		  }
		}
	  );
	  
		return () => map.remove(); // Remove o mapa ao desmontar o componente
	  }
	
	  if (!map) 
		initializeMap();
	
	  }}, [map, marker, page]);
	
	  async function downloadImage(url: RequestInfo | URL, filename: string) {
		try {
		  const response = await fetch(url);
		  if (!response.ok) throw new Error('Falha ao baixar a imagem.');
		  const imageBlob = await response.blob(); // Obtém a resposta como Blob
		  const imageFile = new File([imageBlob], filename, { type: imageBlob.type }); // Converte Blob para File
		  return imageFile;
		} catch (error) {
		  console.error('Erro ao baixar a imagem:', error);
		  return null;
		}
	  }
	  
	  async function captureMap() {
		let url
		if(mapStyle == "mapbox://styles/mapbox/streets-v12") 
		  url = `https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/geojson(%7B%22type%22%3A%22FeatureCollection%22%2C%22features%22%3A%5B%7B%22type%22%3A%22Feature%22%2C%22properties%22%3A%7B%22marker-color%22%3A%22%23FF0000%22%7D%2C%22geometry%22%3A%7B%22type%22%3A%22Point%22%2C%22coordinates%22%3A%5B${markerCoords.lng}%2C${markerCoords.lat}%5D%7D%7D%5D%7D)/${markerCoords.lng},${markerCoords.lat},15/500x300?access_token=pk.eyJ1IjoiamVhbnZyMDk4IiwiYSI6ImNscGQyc2hnejBwaTcya21tY2w3emJpOHAifQ.Aa96RubapYbo0gdZbBUxjw`;
		else if (mapStyle == "mapbox://styles/mapbox/satellite-streets-v12")
		  url = `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/static/geojson(%7B%22type%22%3A%22FeatureCollection%22%2C%22features%22%3A%5B%7B%22type%22%3A%22Feature%22%2C%22properties%22%3A%7B%22marker-color%22%3A%22%23FF0000%22%7D%2C%22geometry%22%3A%7B%22type%22%3A%22Point%22%2C%22coordinates%22%3A%5B${markerCoords.lng}%2C${markerCoords.lat}%5D%7D%7D%5D%7D)/${markerCoords.lng},${markerCoords.lat},15/500x300?access_token=pk.eyJ1IjoiamVhbnZyMDk4IiwiYSI6ImNscGQyc2hnejBwaTcya21tY2w3emJpOHAifQ.Aa96RubapYbo0gdZbBUxjw`;
		
		if(url){
		  console.log('url: ' + url)
		  setCapturedImageUrl(url); 
		  setShowMap(false);
	  
		  const filename = "locality.png"; // Defina um nome de arquivo apropriado
	  
		  downloadImage(url, filename).then(imageFile => {
		  if (imageFile) {
			console.log('Nome do arquivo:', imageFile.name);
			setImageData([...imageData, imageFile])
	  
		  }
		  });
		}
	  }

	function firstPage (){
		return (
			<div className="MainContainer">				
				<div className='SideBarCompensation'/>

				<div className="CentralBlock" style={{height: '100vh'}}>
					<FlagSelector />
					<Sidebar activePage='Register' />

					<div className='RegisterMainContainer'>
						<div className="iconContainer" style={{ width: '100%' }}>
							<div
								className='iconLeft'
								style={{ alignSelf: 'center', width: '7%' }}
								onClick={() => navigate(-1)}
							>

								<FontAwesomeIcon icon={faAngleLeft} title="Empresas já cadastradas" />
							</div>

							<div style={{ display: 'flex', justifyContent: 'space-between', width: '85%' }}>
								<p className='clientText'>
									{edit ? lang.editPlant[currLang] : lang.registerPlant[currLang]}
								</p>

								<div className='folderContainer' onClick={handleManagement}>
									<FontAwesomeIcon icon={faFolder} title="Plantas já cadastradas" />
								</div>
							</div>
						</div>

						<div style={{ width: '85%' }}>
							<text className='equipmentText2'>{lang.plantInfo[currLang]}</text>
						</div>

						<div style={{ padding: '.6rem' }} />

						<div style={{ width: '85%' }}>
							<CustomTextField
								placeholder={lang.name[currLang]}
								value={selectedPlant.name}
								setValue={handleNameChange}
							/>
						</div>

						<div style={{ padding: '.6rem' }} />

						<div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>
							<CustomDropBox
								options={companies}
								value={selectedPlant.company ? selectedPlant.company.name : ''}
								setValue={handleCompanyChange}
								placeholder={lang.company[currLang]}
								key='name'
								callbackKey={lang.addNew[currLang]}
							/>
						</div>

						<div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>
							<SearchBox
								value={selectedPlant.location ? selectedPlant.location : ''}
								title={lang.location[currLang]}
								dict={selectedPlant}
								setValue={setSelectedPlant}
							/>
						</div>

						<div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>
							<FileDocumentUpload
								fileData={fileData}
								setFileData={setFileData}
								title={lang.documents[currLang]}
							/>
						</div>

						<div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>
							<FileUpload
								title={lang.photos[currLang]}
								setImg={setImageData}
								img={imageData.length > 0 ? imageData[0] : null}
							/>
						</div>

						<AsyncButton
							active={loading || isFieldsEmpty()}
							text={lang.next[currLang]}
							className='button-plant'
							onClick={() => setPage(2)}
						/>
					</div>
				</div>
			</div>
		)
	}

	function secondPage(){
		return(
		<div className="MainContainer">
			<CustomModal modalOpen={emailModalOpen} className='emailModalContainer'>
				<div className='textsModalContainer'>
					<h1 className='PageModalTextContainer'>{lang.formRegister[currLang]}</h1>
				</div>
				<div className='textsModalContainer'>
					<p className='PageModalTextContainer'>{lang.formMessage[currLang]}</p>
				</div>
				<div className='modalInputText'>
					<CustomTextField
						placeholder={lang.email[currLang]}
						value={email}
						setValue={handleEmailChange}
					/>
				</div>
				{emailWarning === true && <p style={{ color: 'red'}}>{lang.invalidEmail[currLang]}</p>}
				
				<div className='modalInputText'>	
					<CustomTextField
						placeholder={lang.message[currLang]}
						value={emailMessage}
						setValue={setEmailMessage}
					/>
				</div>
				<div className='modalInputText'>
					<CustomTextField
						placeholder={'Link'}
						value={formLink}
						disabled={true}
						//navigator.clipboard.writeText(selectedPlant.name)
					/>
					<FontAwesomeIcon 
						className={copy ? 'copyIconModalDisabled' : 'copyIconModal'}
						icon={copy ? faCheck : faCopy} 
						title="Copiar para área de transferência" 
						onClick={() => copy ? undefined : handleCopy()} 
					/>
				</div>
				
				<div className='modalButtonWrapper'>
					<AsyncButton
						active={loading || isModalFieldsEmpty()}
						text={lang.send[currLang]}
						className='PageModalButtonContainer'
						onClick={handleSendMail}
						loading={loading}
					/>

					<AsyncButton
						active={loading}
						text={lang.close[currLang]}
						className='PageModalButtonContainer'
						onClick={handleOpenNextModal}
						loading={loading}
					/>
				</div>
			</CustomModal>

			<CustomModalPage
				title={lang.plantRegistered[currLang]}
				subtitle={lang.wantToRegisterProcess[currLang]}
				yesPage='/Process?insert'
				noPage='/Home'
				yesText={lang.yes[currLang]}
				noText={lang.no[currLang]}
				modal={nextModalOpen}
			/>

		  <div className='SideBarCompensation'/>
	
		  <div className="CentralBlock" style={{height: '100vh'}}>
			<FlagSelector/>
			<Sidebar activePage='Register'/>
			  
			<div className="RegisterMainContainer">
			  <div className="iconContainer" style={{width: '100%'}}>
				  <div
					className='iconLeft' 
					style={{alignSelf: 'center', width: '7%'}}
					onClick={handleBack}
					>
					
					<FontAwesomeIcon icon={faAngleLeft} title="Empresas já cadastradas"/> 
				  </div>
	
				  <div style={{display: 'flex', justifyContent: 'space-between', width: '85%'}}>
					<p className='clientText'>
					  {edit ? lang.editPlant[currLang] : lang.registerPlant[currLang]}
					</p>
				  
					<div className='folderContainer' onClick={handleManagement}>
					  <FontAwesomeIcon icon={faFolder} title="Processos já cadastrados"/>        
					</div>
				  </div>
			  </div>          
	
			  <div style={{width: '85%'}}>
				{showMap ? (
				  <div form-container-process>
					<div style={{width: '85%'}}>
					  <text className='equipmentText2'>{lang.plantMarker[currLang]}</text>
					</div>
	
					<div style={{padding: '.6rem'}}/>
	
					<div ref={mapContainer} style={{ width: '100%', height: '300px' }}/>
	
					<div style={{padding: '.6rem'}}/>
	
					<button 
					  onClick={captureMap} 
					  className='button-process' 
					  style={isMarkerSet() ?  {width: '100%'} : {opacity: 0.8, cursor: 'not-allowed', width: '100%'}}
					  disabled={!isMarkerSet()}
					>
					  {lang.captureMap[currLang]}
					</button>
	
				  </div>
	
				) : (
				  <div style={{width: '100%'}}>
					<div style={{width: '85%'}}>
					  <text className='equipmentText2'>{lang.plantCapture[currLang]}</text>
					</div>
	
					<div style={{padding: '.6rem'}}/>
					<img src={capturedImageUrl} alt="Mapa Capturado" style={{ width: '100%', height: '100%' }}/>
					<div style={{padding: '.6rem'}}/>
	
					<AsyncButton
					  active={loading || isFieldsEmpty()}
					  text={edit ? lang.edit[currLang] : lang.register[currLang]}
					  className='button-process'
					  onClick={
						edit 
						? 
						async () => {setLoading(true); await handleEdit(); setLoading(false)}
						:
						async () => {setLoading(true); await handleSubmit(); setLoading(false)}
					  }
					  loading={loading}
					/>
				  </div>
	
				)}
			  </div>
			</div>
			</div>
		  </div>
		);
	  }

	return (page == 1) ? firstPage() : secondPage()
}
