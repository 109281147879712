const lang: any = {
    export: {
        PT: 'Exportar',
        US: 'Export',
        ES: 'Exportar'
    },

    overview: {
        PT: 'Visão Geral',
        US: 'Overview',
        ES: 'Visión General'
    },

    relatory: {
        PT: 'Relatório',
        US: 'Relatory',
        ES: 'Informe'
    },

    report: {
        PT: "Relatório",
        US: "Report",
        ES: "Informe"
    },    

    photos: {
        PT: 'Fotos',
        US: 'Photos',
        ES: 'Fotos'
    },

    createdOn: {
        PT: 'Criado em',
        US: 'Created On',
        ES: 'Creado En'
    },

    deadline: {
        PT: 'Prazo',
        US: 'Deadline',
        ES: 'Plazo'
    },

    startDate: {
        PT: 'Data de início',
        US: 'Start date',
        ES: 'Fecha de inicio'
    },

    company: {
        PT: 'Empresa',
        US: 'Company',
        ES: 'Empresa'
    },

    plant: {
        PT: 'Planta',
        US: 'Plant',
        ES: 'Planta'
    },

    process: {
        PT: 'Processo',
        US: 'Process',
        ES: 'Proceso'
    },

    inspectorName: {
        PT: 'Nome do Inspetor',
        US: 'Inspector Name',
        ES: 'Nombre del Inspector'
    },

    equipment: {
        PT: 'Tag do Equipamento',
        US: 'Equipment Tag',
        ES: 'Tag del Equipo'
    },

    lastInspection: {
        PT: 'Última Inspeção',
        US: 'Last Inspection',
        ES: 'Última Inspección'
    },

    equipmentName: {
        PT: 'Nome do Equipamento',
        US: 'Equipment Name',
        ES: 'Nombre del Equipo'
    },

    observations: {
        PT: "Observações",
        US: "Observations",
        ES: "Observaciones"
    },

    RepairData: {
        PT: "Dados de Reparo",
        US: "Repair Data",
        ES: "Datos de Reparación"
    },

    BasicData: {
        PT: "Dados Básicos",
        US: "Basic Data",
        ES: "Datos Básicos"
    },
    
    Recommendations: {
        PT: "Recomendações",
        US: "Recommendations",
        ES: "Recomendaciones"
    },

    CoatingEvaluation: {
        PT: "Avaliação de Revestimento",
        US: "Coating Evaluation",
        ES: "Evaluación de Revestimiento"
    },

    SubstrateEvaluation: {
        PT: "Avaliação de Substrato",
        US: "Substrate Evaluation",
        ES: "Evaluación de Sustrato"
    },
    
    SectorFootage: {
        PT: "Metragem por Setor",
        US: "Sector Footage",
        ES: "Metraje por Sector"
    },

    //--------- RepairData
    repairPercent: {
        PT: "% Reparo",
        US: "% Repair",
        ES: "% Reparación"
    },

    repair: {
        PT: "Reparo",
        US: "Repair",
        ES: "Reparación"
    },

    meterage: {
        PT: "Metragem",
        US: "Meterage",
        ES: "Medición"
    },

    totalMeterage: {
        PT: "Metragem Total",
        US: "Total Meterage",
        ES: "Medición Total"
    },
    //--------- End RepairData

    //--------- Recommendations
    points: {
        PT: 'Pontos Avaliados',
        US: 'Evaluated Points',
        ES: 'Puntos Evaluados'
    },

    time: {
        PT: "Tempo",
        US: "Time",
        ES: "Tiempo"
    },

    repairType: {
        PT: "Tipo de Reparo",
        US: "Repair Type",
        ES: "Tipo de Reparación"
    },

    access: {
        PT: "Acesso",
        US: "Access",
        ES: "Acceso"
    },

    repairHeight: {
        PT: "Altura do Reparo",
        US: "Repair Height",
        ES: "Altura de la Reparación"
    },
    //--------- End Recommendations
    
    //--------- Basics
    substrate: {
        PT: 'Substrato',
        US: 'Substrate',
        ES: 'Sustrato'
    },

    coating: {
        PT: 'Revestimento',
        US: 'Coating',
        ES: 'Revestimiento'
    },
    
    temperature: {
        PT: 'Temperatura de Operação',
        US: 'Operation Temperature',
        ES: 'Temperatura de Operación'
    },

    insulation: {
        PT: 'Isolamento Térmico',
        US: 'Thermal Insulation',
        ES: 'Aislamiento Térmico'
    },

    PFP: {
        PT: 'Proteção Passiva contra Incêndio',
        US: 'Passive Fire Protection',
        ES: 'Protección Pasiva contra Incendios'
    },
    
    heightWork: {
        PT: 'Trabalho em Altura',
        US: 'Work at Height',
        ES: 'Trabajo en Altura'
    },

    confinedSpace: {
        PT: 'Espaço Confinado',
        US: 'Confined Space',
        ES: 'Espacio Confinado'
    },
    //--------- End Basics

    //--------- CoatingEvaluation
    pollutantIncidency: {
        PT: "Incidência de Contaminantes",
        US: "Incidence of Contaminants",
        ES: "Incidencia de Contaminantes"
    },

    mechanicDamage: {
        PT: "Incidência de Danos Mecânicos",
        US: "Incidence of Mechanical Damage",
        ES: "Incidencia de Daños Mecánicos"
    },

    coatingDegradation: {
        PT: "Dano no Revestimento",
        US: "Coating Damage",
        ES: "Daño en el Revestimiento"
    },

    coatingDetachment: {
        PT: "Descolamento do Revestimento",
        US: "Coating Detachment",
        ES: "Desprendimiento del Revestimiento"
    },

    degradationClass: {
        PT: "Classe da Degradação",
        US: "Degradation Class",
        ES: "Clase de Degradación"
    },

    degradationSeverity: {
        PT: "Severidade do Dano",
        US: "Damage Severity",
        ES: "Severidad del Daño"
    },
    //--------- End CoatingEvaluation

    //--------- SubstrateEvaluation
    corrosionCharacteristics: {
        PT: "Características da Corrosão",
        US: "Corrosion Characteristics",
        ES: "Características de la Corrosión"
    },

    aggressive: {
        PT: "Quantidade Pites/Alvéolos agressivos",
        US: "Quantity of Aggressive Pits/Honeycombs",
        ES: "Cantidad de Pits/Alvéolos Agressivos"
    },

    underCorrosion: {
        PT: "Corrosão Sob Filme",
        US: "Underfilm Corrosion",
        ES: "Corrosión Bajo Película"
    },

    galvanicCorrosion: {
        PT: "Incidência de Corrosão Galvânica",
        US: "Incidence of Galvanic Corrosion",
        ES: "Incidencia de Corrosión Galvánica"
    },

    corrosionSeverity: {
        PT: "Severidade da Corrosão",
        US: "Corrosion Severity",
        ES: "Severidad de la Corrosión"
    },

    corrosion: {
        PT: "% Corrosão",
        US: "% Corrosion",
        ES: "% Corrosión"
    },

    materialLoss: {
        PT: "Perda de Material",
        US: "Material Loss",
        ES: "Pérdida de Material"
    },
    //--------- End SubstrateEvaluation

    //--------- SectorFootage
    itemDescription: {
        PT: "Descrição do Item",
        US: "Item Description",
        ES: "Descripción del Ítem"
    },

    dimensionPol: {
        PT: "Dimensão em Polegadas",
        US: "Dimension in Inches",
        ES: "Dimensión en Pulgadas"
    },

    dimensionMeter: {
        PT: "Dimensão em Metros",
        US: "Dimension in Meters",
        ES: "Dimensión en Metros"
    },

    inputWidth: {
        PT: "Largura de Entrada",
        US: "Input Width",
        ES: "Ancho de Entrada"
    },

    inputLength: {
        PT: "Comprimento de Entrada",
        US: "Input Length",
        ES: "Longitud de Entrada"
    },

    quantity: {
        PT: "Quantidade",
        US: "Quantity",
        ES: "Cantidad"
    },

    totalArea: {
        PT: "Área Total",
        US: "Total Area",
        ES: "Área Total"
    },
    //--------- End SectorFootage

    recommendations: {
        PT: 'Recomendação',
        US: 'Recommendations',
        ES: 'Recomendaciones'
    },

    close: {
        PT: 'Fechar',
        US: 'Close',
        ES: 'Cerrar'
    },

    exportXLSX: {
        PT: 'Exportar como XLSX',
        US: 'Export as XLSX',
        ES: 'Exportar como XLSX'
    },

    generalInformation: {
        PT: 'Informações Gerais',
        US: 'General Information',
        ES: 'Información General'
    },

    edit: {
        PT: 'Editar',
        US: 'Edit',
        ES: 'Editar'
    },

    approve: {
        PT: 'Aprovar',
        US: 'Approve',
        ES: 'Aprobar'
    },

    notification: {
        title: {
            PT: 'Inspeção verificada',
            US: 'Inspection verified',
            ES: 'Inspección verificada'
        },
        content: {
            PT: 'A inspeção {{inspectionTitle}} acabou de ser verificada!',
            US: 'The inspection {{inspectionTitle}} was just verified!',
            ES: '¡La inspección {{inspectionTitle}} acaba de ser verificada!'
        }
    },

    rectifications: {
        PT: 'Retificações',
        US: 'Rectifications',
        ES: 'Retificaciones'
    },

    rectify: {
        PT: 'Retificar',
        US: 'Rectify',
        ES: 'Rectificar'
    },

    comments: {
        PT: 'Comentários',
        US: 'Comments',
        ES: 'Comentarios'
    },

    inspectionRectification: {
        PT: 'Retificação de inspeção',
        US: 'Inspection Rectification',
        ES: 'Rectificación de Inspección'
    },

    visualInspection: {
        PT: 'Inspeção Visual',
        US: 'Visual Inspection',
        ES: 'Inspección Visual'
    },

    type: {
        PT: 'Tipo de Inspeção',
        US: 'Type of Inspection',
        ES: 'Tipo de Inspección'
    },

    corrosionType: {
        PT: 'Inspeção de Corrosão',
        US: 'Corrosion Inspection',
        ES: 'Inspección de Corrosión'
    },

    painting: {
        PT: 'Inspeção de Pintura',
        US: 'Paint Inspection',
        ES: 'Inspección de Pintura'
    },
    NR13: {
        PT: 'Inspeção de Segurança - Equipamentos (NR13)',
        US: 'Safety Inspection (NR13) - Equipments',
        ES: 'Inspección Seguridad (NR13) - Equipos'
    },
    
    Abrasive: {
        PT: "Abrasivo",
        US: "Abrasive",
        ES: "Abrasivo"
    },
    Metadata: {
        PT: "Dados Básicos",
        US: "Basic Data",
        ES: "Datos Básicos"
    },
    abrasiveType: {
        PT: "Tipo de Abrasivo",
        US: "Abrasive Type",
        ES: "Tipo de Abrasivo"
    },
    certificate: {
        PT: "Certificado",
        US: "Certificate",
        ES: "Certificado"
    },
    invoice: {
        PT: "Fatura",
        US: "Invoice",
        ES: "Factura"
    },
    suplier: {
        PT: "Fornecedor",
        US: "Supplier",
        ES: "Proveedor"
    },
    Visual: {
        PT: "Visual",
        US: "Visual",
        ES: "Visual"
    },
    visualAplicable: {
        PT: "Aplicável Visual",
        US: "Visual Applicable",
        ES: "Aplicable Visual"
    },
    pollutantAplicable: {
        PT: "Aplicável a Poluentes",
        US: "Pollutant Applicable",
        ES: "Aplicable a Contaminantes"
    },
    hasPollutant: {
        PT: "Contém Poluentes",
        US: "Has Pollutant",
        ES: "Contiene Contaminantes"
    },
    pollutantDesc: {
        PT: "Descrição do Poluente",
        US: "Pollutant Description",
        ES: "Descripción del Contaminante"
    },
    result: {
        PT: "Resultado",
        US: "Result",
        ES: "Resultado"
    },
    Granulometry: {
        PT: "Granulometria",
        US: "Granulometry",
        ES: "Granulometría"
    },
    aplicableInspection: {
        PT: "Inspeção Aplicável",
        US: "Applicable Inspection",
        ES: "Inspección Aplicable"
    },
    meshCount: {
        PT: "Contagem de Malha",
        US: "Mesh Count",
        ES: "Conteo de Malla"
    },
    meshesRetentions: {
        PT: "Retenções de Malhas",
        US: "Meshes Retentions",
        ES: "Retenciones de Mallas"
    },
    mesh: {
        PT: "Malha",
        US: "Mesh",
        ES: "Malla"
    },
    Salts: {
        PT: "Sais",
        US: "Salts",
        ES: "Sales"
    },
    saltsQuantity: {
        PT: "Quantidade de Sais",
        US: "Salts Quantity",
        ES: "Cantidad de Sales"
    },
    obs: {
        PT: "Observações",
        US: "Observations",
        ES: "Observaciones"
    },

    inspectedInk: {
        PT: "Tinta Inspecionada",
        US: "Inspected Ink",
        ES: "Tinta Inspeccionada"
    },
    receivedQuantity: {
        PT: "Quantidade Recebida",
        US: "Received Quantity",
        ES: "Cantidad Recibida"
    },
    inspectedQuantity: {
        PT: "Quantidade Inspecionada",
        US: "Inspected Quantity",
        ES: "Cantidad Inspeccionada"
    },
    receivedDate: {
        PT: "Data de Recebimento",
        US: "Received Date",
        ES: "Fecha de Recepción"
    },
    color: {
        PT: "Cor",
        US: "Color",
        ES: "Color"
    },
    batch: {
        PT: "Lote",
        US: "Batch",
        ES: "Lote"
    },
    inkDeadline: {
        PT: "Prazo da Tinta",
        US: "Ink Deadline",
        ES: "Plazo de la Tinta"
    },
    security: {
        PT: "Segurança",
        US: "Security",
        ES: "Seguridad"
    },
    correctId: {
        PT: "Identificação Correta",
        US: "Correct ID",
        ES: "ID Correcto"
    },
    deficiency: {
        PT: "Deficiência",
        US: "Deficiency",
        ES: "Deficiencia"
    },
    imperfect: {
        PT: "Imperfeição",
        US: "Imperfect",
        ES: "Imperfección"
    },
    exudation: {
        PT: "Exsudação",
        US: "Exudation",
        ES: "Exudación"
    },
    dent: {
        PT: "Amolgamento",
        US: "Dent",
        ES: "Abolladura"
    },
    cuts: {
        PT: "Cortes",
        US: "Cuts",
        ES: "Cortes"
    },
    insecurity: {
        PT: "Insegurança",
        US: "Insecurity",
        ES: "Inseguridad"
    },
    badConservation: {
        PT: "Má Conservação",
        US: "Bad Conservation",
        ES: "Mala Conservación"
    },
    markDeficiency: {
        PT: "Deficiência de Marcação",
        US: "Mark Deficiency",
        ES: "Deficiencia de Marca"
    },
    otherDefects: {
        PT: "Outros Defeitos",
        US: "Other Defects",
        ES: "Otros Defectos"
    },
    correctiveAction: {
        PT: "Ação Corretiva",
        US: "Corrective Action",
        ES: "Acción Correctiva"
    },
    correctiveNum: {
        PT: "Número da Ação Corretiva",
        US: "Corrective Number",
        ES: "Número de la Acción Correctiva"
    },
    correctiveDate: {
        PT: "Data da Ação Corretiva",
        US: "Corrective Date",
        ES: "Fecha de la Acción Correctiva"
    },
    standards: {
        PT: "Normas",
        US: "Standards",
        ES: "Normas"
    },
    Ink: {
        PT: "Tinta",
        US: "Ink",
        ES: "Tinta"
    },
    InkEnd: {
        PT: "Resultado da Inspeção",
        US: "Inspection Outcome",
        ES: "Resultado de la Inspección"
    },
    fabricationDate: {
        PT: "Data de Fabricação",
        US: "Fabrication Date",
        ES: "Fecha de Fabricación"
    },
    InspectionChars: {
        PT: "Características de Inspeção",
        US: "Inspection Characteristics",
        ES: "Características de Inspección"
    },
    select: {
        'PT': 'Selecionar',
        'US': 'Select',
        'ES': 'Seleccionar'
    },
    yes: {
        PT: 'SIM',
        US: 'YES',
        ES: 'SI'
    },

    no: {
        PT: 'NÃO',
        US: 'NO',
        ES: 'NO'
    },
    approved: {
        PT: 'APROVADO',
        US: 'APPROVED',
        ES: 'APROBADO'
    },
    rejected: {
        PT: 'REPROVADO',
        US: 'REJECTED',
        ES: 'REPROBADO'
    },
    Climate: {
        PT: 'Clima',
        US: 'Climate',
        ES: 'Clima'
    },
    day: {
        PT: 'Dia',
        US: 'Day',
        ES: 'Día'
    },
    
    morning: {
        PT: 'Manhã',
        US: 'Morning',
        ES: 'Mañana'
    },
    
    humidity: {
        PT: 'Umidade',
        US: 'Humidity',
        ES: 'Humedad'
    },
    
    dewPoint: {
        PT: 'Ponto de Orvalho',
        US: 'Dew Point',
        ES: 'Punto de Rocío'
    },
    
    midDay: {
        PT: 'Meio-dia',
        US: 'Midday',
        ES: 'Mediodía'
    },
    
    afternoon: {
        PT: 'Tarde',
        US: 'Afternoon',
        ES: 'Tarde'
    },
    
    conditions: {
        PT: 'Condições',
        US: 'Conditions',
        ES: 'Condiciones'
    },
    
    _editTimestamp: {
        PT: 'TIMESTAMP TESTE',
        US: 'TIMESTAMP TESTE',
        ES: 'TIMESTAMP TESTE'
    },

    RIP: {
        PT: 'Resultados de Pintura',
        US: 'Painting Results',
        ES: 'Resultados de Pintura'
    },

    ambientTemp: {
        PT: "Temperatura Ambiente",
        US: "Ambient Temperature",
        ES: "Temperatura Ambiente"
    },
    surfaceTemp: {
        PT: "Temperatura da Superfície",
        US: "Surface Temperature",
        ES: "Temperatura de la Superficie"
    },
    chloride: {
        PT: "Cloreto",
        US: "Chloride",
        ES: "Cloruro"
    },
    waterQuality: {
        PT: "Qualidade da Água",
        US: "Water Quality",
        ES: "Calidad del Agua"
    },
    surfaceChloride: {
        PT: "Cloreto na Superfície",
        US: "Surface Chloride",
        ES: "Cloruro en la Superficie"
    },
    minPrepare1: {
        PT: "Preparação Mínima 1",
        US: "Minimum Preparation 1",
        ES: "Preparación Mínima 1"
    },
    desiredRough: {
        PT: "Aspereza Desejada",
        US: "Desired Roughness",
        ES: "Rugosidad Deseada"
    },
    minPrepare2: {
        PT: "Preparação Mínima 2",
        US: "Minimum Preparation 2",
        ES: "Preparación Mínima 2"
    },

    appliedInkNum: {
        PT: "Número de Tinta Aplicada",
        US: "Applied Ink Number",
        ES: "Número de Tinta Aplicada"
    },
    abrasiveNum: {
        PT: "Número de Abrasivo",
        US: "Abrasive Number",
        ES: "Número de Abrasivo"
    },
    usedAbrasive: {
        PT: "Abrasivo Utilizado",
        US: "Used Abrasive",
        ES: "Abrasivo Utilizado"
    },
    inkRcvNum: {
        PT: "Número de Tinta Recebida",
        US: "Ink Received Number",
        ES: "Número de Tinta Recibida"
    },
    manufacturer: {
        PT: "Fabricante",
        US: "Manufacturer",
        ES: "Fabricante"
    },
    certification: {
        PT: "Certificação",
        US: "Certification",
        ES: "Certificación"
    },
    batchA: {
        PT: "Lote A",
        US: "Batch A",
        ES: "Lote A"
    },
    validityA: {
        PT: "Validade A",
        US: "Validity A",
        ES: "Validez A"
    },
    batchB: {
        PT: "Lote B",
        US: "Batch B",
        ES: "Lote B"
    },
    validityB: {
        PT: "Validade B",
        US: "Validity B",
        ES: "Validez B"
    },
    batchC: {
        PT: "Lote C",
        US: "Batch C",
        ES: "Lote C"
    },
    validityC: {
        PT: "Validade C",
        US: "Validity C",
        ES: "Validez C"
    },
    appStart: {
        PT: "Início da Aplicação",
        US: "Application Start",
        ES: "Inicio de la Aplicación"
    },
    appStartDate: {
        PT: "Data de Início da Aplicação",
        US: "Application Start Date",
        ES: "Fecha de Inicio de la Aplicación"
    },
    appEnd: {
        PT: "Fim da Aplicação",
        US: "Application End",
        ES: "Final de la Aplicación"
    },
    appEndDate: {
        PT: "Data de Fim da Aplicação",
        US: "Application End Date",
        ES: "Fecha de Finalización de la Aplicación"
    },
    appMethod: {
        PT: "Método de Aplicação",
        US: "Application Method",
        ES: "Método de Aplicación"
    },
    thickness: {
        PT: "Espessura",
        US: "Thickness",
        ES: "Espesor"
    },
    area: {
        PT: "Área",
        US: "Area",
        ES: "Área"
    },
    nonConformity: {
        PT: "Não Conformidade",
        US: "Non-Conformity",
        ES: "No Conformidad"
    },
    discontinuity: {
        PT: "Descontinuidade",
        US: "Discontinuity",
        ES: "Discontinuidad"
    },
    GeneralConditions: {
        PT: "Condições Gerais",
        US: "General Conditions",
        ES: "Condiciones Generales"
    },
    AppInspection: {
        PT: "Inspeção de Aplicação",
        US: "Application Inspection",
        ES: "Inspección de Aplicación"
    },
    onPainting: {
        PT: "Durante a Pintura",
        US: "On Painting",
        ES: "Durante la Pintura"
    },
    onPrepare: {
        PT: "Durante a Preparação",
        US: "On Preparation",
        ES: "Durante la Preparación"
    },
    finish1: {
        PT: "Acabamento 1",
        US: "Finish 1",
        ES: "Acabado 1"
    },
    finish2: {
        PT: "Acabamento 2",
        US: "Finish 2",
        ES: "Acabado 2"
    },
    bottom: {
        PT: "Fundo",
        US: "Bottom",
        ES: "Fondo"
    },
    extra: {
        PT: "Extra",
        US: "Extra",
        ES: "Extra"
    },
    



    WetFilm: {
        PT: "FILME ÚMIDO",
        US: "WET FILM",
        ES: "PELÍCULA HÚMEDA"
    },
    measures: {
        PT: "MEDIDAS",
        US: "MEASURES",
        ES: "MEDIDAS"
    },
    inkNum: {
        PT: "NÚMERO DA TINTA",
        US: "INK NUMBER",
        ES: "NÚMERO DE TINTA"
    },
    Tags: {
        PT: "ETIQUETAS",
        US: "TAGS",
        ES: "ETIQUETAS"
    },
    instruments: {
        PT: "INSTRUMENTOS",
        US: "INSTRUMENTS",
        ES: "INSTRUMENTOS"
    },
    cert: {
        PT: "CERTIFICADO",
        US: "CERT",
        ES: "CERTIFICADO"
    },
    tag: {
        PT: "ETIQUETA",
        US: "TAG",
        ES: "ETIQUETA"
    },
    PaintMeasures: {
        PT: "MEDIDAS DA PINTURA",
        US: "PAINT MEASURES",
        ES: "MEDIDAS DE PINTURA"
    },
    itemMeasures: {
        PT: "MEDIDAS DO ITEM",
        US: "ITEM MEASURES",
        ES: "MEDIDAS DEL ÍTEM"
    },
    measureResult: {
        PT: "RESULTADO DA MEDIÇÃO",
        US: "MEASURE RESULT",
        ES: "RESULTADO DE MEDICIÓN"
    },
    tapes: {
        PT: "FITAS",
        US: "TAPES",
        ES: "CINTAS"
    },
    pins: {
        PT: "PINS",
        US: "PINS",
        ES: "PINS"
    },
    standardResult: {
        PT: "RESULTADO PADRÃO",
        US: "STANDARD RESULT",
        ES: "RESULTADO ESTÁNDAR"
    },
    Roughness: {
        PT: "ASPEREZA",
        US: "ROUGHNESS",
        ES: "RUGOSIDAD"
    },
    mean: {
        PT: "MÉDIA",
        US: "MEAN",
        ES: "PROMEDIO"
    },
    

    
    evaluationReport: {
        PT: 'RELATÓRIO DE SEGURANÇA (NR13)',
        US: 'SAFETY REPORT (NR13)',
        ES: 'INFORME DE SEGURIDAD (NR13)'  
    },
    date: {
        PT: 'Data',
        US: 'Date',
        ES: 'Fecha'
    },
    industrialUnit: {
        PT: 'Unidade Industrial',
        US: 'Industrial Unit',
        ES: 'Unidad Industrial'
    },
    areaSector: {
        PT: 'Área / Setor',
        US: 'Area / Sector',
        ES: 'Área / Sector'
    },
    component: {
        PT: 'Componente',
        US: 'Component',
        ES: 'Componente'
    },
    evaluatorInspector: {
        PT: 'Avaliador / Inspetor',
        US: 'Evaluator / Inspector',
        ES: 'Evaluador / Inspector'
    },
    supervisor: {
        PT: 'Supervisor',
        US: 'Supervisor',
        ES: 'Supervisor'
    },
    equipmentData: {
        PT: "DADOS DO EQUIPAMENTO",
        US: "EQUIPMENT DATA",
        ES: "DATOS DEL EQUIPO"
    },
    
    serialNumberOrOP: {
        PT: "Número de Série ou OP",
        US: "Serial Number or OP",
        ES: "Número de Serie o OP"
    },
    
    tagOrPosition: {
        PT: "Tag ou Posição",
        US: "Tag or Position",
        ES: "Etiqueta o Posición"
    },
    
    function: {
        PT: "Função",
        US: "Function",
        ES: "Función"
    },
    
    pmta: {
        PT: "PMTA",
        US: "PMTA",
        ES: "PMTA"
    },
    
    designPressure: {
        PT: "Pressão de Projeto",
        US: "Design Pressure",
        ES: "Presión de Diseño"
    },
    
    thPressure: {
        PT: "Pressão TH",
        US: "TH Pressure",
        ES: "Presión TH"
    },
    
    operatingPressure: {
        PT: "Pressão de Operação",
        US: "Operating Pressure",
        ES: "Presión de Operación"
    },
    
    designTemperature: {
        PT: "Temperatura de Projeto",
        US: "Design Temperature",
        ES: "Temperatura de Diseño"
    },
    
    manufactureDate: {
        PT: "Data de Fabricação",
        US: "Manufacture Date",
        ES: "Fecha de Fabricación"
    },
    
    installationDate: {
        PT: "Data de Instalação",
        US: "Installation Date",
        ES: "Fecha de Instalación"
    },
    
    jointEfficiency: {
        PT: "Eficiência da Junta",
        US: "Joint Efficiency",
        ES: "Eficiencia de la Junta"
    },
    
    xRay: {
        PT: "Raio-X",
        US: "X-Ray",
        ES: "Rayos X"
    },
    
    nr13Classification: {
        PT: "Classificação NR13",
        US: "NR13 Classification",
        ES: "Clasificación NR13"
    },
    
    material: {
        PT: "Material",
        US: "Material",
        ES: "Material"
    },
    
    fluid: {
        PT: "Fluido",
        US: "Fluid",
        ES: "Fluido"
    },
    
    volume: {
        PT: "Volume",
        US: "Volume",
        ES: "Volumen"
    },
    inspectionData: {
        PT: "DADOS DE INSPEÇÃO",
        US: "INSPECTION DATA",
        ES: "DATOS DE INSPECCIÓN"
    },    
    


    intern: {
        PT: "Interna",
        US: "Internal",
        ES: "Interna"
    },
    
    extern: {
        PT: "Externa",
        US: "External",
        ES: "Externa"
    },
    
    hidrostatic: {
        PT: "Teste Hidrostático",
        US: "Hydrostatic Test",
        ES: "Prueba Hidrostática"
    },
    
    extraordinary: {
        PT: "Extraordinário",
        US: "Extraordinary",
        ES: "Extraordinario"
    },
    
    PAR: {
        PT: "PAR",
        US: "MOC",
        ES: "PAR"
    },
    
    others: {
        PT: "Outros",
        US: "Others",
        ES: "Otros"
    },
    
    endDate: {
        PT: "Data de Término",
        US: "End Date",
        ES: "Fecha de Finalización"
    },
    relatoryDate: {
        PT: "Data do Relatório",
        US: "Report Date",
        ES: "Fecha del Informe"
    },
    inspectionObjectives: {
        PT: "OBJETIVOS DA INSPEÇÃO",
        US: "INSPECTION OBJECTIVES",
        ES: "OBJETIVOS DE LA INSPECCIÓN"
    },
    technicalReferences: {
        PT: "REFERÊNCIAS TÉCNICAS",
        US: "TECHNICAL REFERENCES",
        ES: "REFERENCIAS TÉCNICAS"
    },    

    usedCode: {
        PT: "Código Utilizado",
        US: "Used Code",
        ES: "Código Utilizado"
    },
    
    inspectionStandards: {
        PT: "Normas de Inspeção",
        US: "Inspection Standards",
        ES: "Normas de Inspección"
    },
    
    procedures: {
        PT: "Procedimentos de Inspeção",
        US: "Inspection Procedures",
        ES: "Procedimientos de Inspección"
    },
    
    ART: {
        PT: "Número da ART",
        US: "ART Number",
        ES: "Número de la ART"
    },

    OperationalData: {
        PT: "DADOS OPERACIONAIS",
        US: "OPERATIONAL DATA",
        ES: "DATOS OPERACIONALES"
    },
    equipmentTag: {
        PT: "TAG DO EQUIPAMENTO",
        US: "EQUIPMENT TAG",
        ES: "ETIQUETA DEL EQUIPO"
    },
    opMode: {
        PT: "Modo de Operação",
        US: "Operation Mode",
        ES: "Modo de Operación"
    },
    vacuumCondition: {
        PT: "Sob Condição de Vácuo",
        US: "Under Vacuum Condition",
        ES: "Bajo Condición de Vacío"
    },
    coils: {
        PT: "Bobinas",
        US: "Coils",
        ES: "Bobinas"
    },
    
    tubes: {
        PT: "Tubo",
        US: "Tubes",
        ES: "Tubos"
    },
    
    jacket: {
        PT: "Jaqueta",
        US: "Jacket",
        ES: "Chaqueta"
    },
    
    shell: {
        PT: "Carcaça",
        US: "Shell",
        ES: "Cáscara"
    },
    refDraws: {
        PT: "Desenhos de Referência",
        US: "Reference Drawings",
        ES: "Dibujos de Referencia"
    },
    
    record: {
        PT: "Registro",
        US: "Record",
        ES: "Registro"
    },
    
    projTemperature: {
        PT: "Temperatura de Projeto",
        US: "Project Temperature",
        ES: "Temperatura de Proyecto"
    },
    
    THPressure: {
        PT: "Pressão TH",
        US: "TH Pressure",
        ES: "Presión TH"
    },
    
    projPressure: {
        PT: "Pressão de Projeto",
        US: "Project Pressure",
        ES: "Presión de Proyecto"
    },
    
    PMTA: {
        PT: "PMTA",
        US: "PMTA",
        ES: "PMTA"
    },
    
    fluidClass: {
        PT: "Classe do Fluido",
        US: "Fluid Class",
        ES: "Clase de Fluido"
    },

    opPressure: {
        PT: "Pressão de Operação",
        US: "Operating Pressure",
        ES: "Presión de Operación"
    },
    
    pressurized: {
        PT: "Pressurizado",
        US: "Pressurized",
        ES: "Presurizado"
    },




















    Inspections: {
        PT: "Inspeções",
        US: "Inspections",
        ES: "Inspecciones"
    },
    
    data: {
        PT: "Dados",
        US: "Data",
        ES: "Datos"
    },
    
    hasNR13Id: {
        PT: "Possui Identificação NR13",
        US: "Has NR13 ID",
        ES: "Tiene Identificación NR13"
    },
    
    hasId: {
        PT: "Possui Identificação",
        US: "Has ID",
        ES: "Tiene Identificación"
    },
    
    hasSec: {
        PT: "Possui Segurança",
        US: "Has Security",
        ES: "Tiene Seguridad"
    },
    
    PVS: {
        PT: "PVS",
        US: "PVS",
        ES: "PVS"
    },
    
    fieldPressure: {
        PT: "Pressão de Campo",
        US: "Field Pressure",
        ES: "Presión de Campo"
    },
    
    remotePressure: {
        PT: "Pressão Remota",
        US: "Remote Pressure",
        ES: "Presión Remota"
    },
    
    gnd: {
        PT: "Terra",
        US: "Ground",
        ES: "Tierra"
    },
    
    building: {
        PT: "Prédio",
        US: "Building",
        ES: "Edificio"
    },
    
    retainingWall: {
        PT: "Muro de Contenção",
        US: "Retaining Wall",
        ES: "Muro de Contención"
    },
    
    multipleAccess: {
        PT: "Acesso Múltiplo",
        US: "Multiple Access",
        ES: "Acceso Múltiple"
    },
    
    isIdentified: {
        PT: "Está Identificado",
        US: "Is Identified",
        ES: "Está Identificado"
    },
    
    hasPlateId: {
        PT: "Possui Placa de Identificação",
        US: "Has Plate ID",
        ES: "Tiene Placa de Identificación"
    },
    
    hasProjData: {
        PT: "Possui Dados de Projeto",
        US: "Has Project Data",
        ES: "Tiene Datos del Proyecto"
    },
    
    seriesNum: {
        PT: "Número de Série",
        US: "Serial Number",
        ES: "Número de Serie"
    },
    
    func: {
        PT: "Função",
        US: "Function",
        ES: "Función"
    },
    
    jointEficiency: {
        PT: "Eficiência da Junta",
        US: "Joint Efficiency",
        ES: "Eficiencia de la Junta"
    },
    
    xray: {
        PT: "Raio-X",
        US: "X-Ray",
        ES: "Rayos X"
    },
    
    NR13Class: {
        PT: "Classificação NR13",
        US: "NR13 Classification",
        ES: "Clasificación NR13"
    },
    
    SecurityInstruments: {
        PT: "Instrumentos de Segurança",
        US: "Security Instruments",
        ES: "Instrumentos de Seguridad"
    },
    
    brand: {
        PT: "Marca",
        US: "Brand",
        ES: "Marca"
    },
    
    calibCertNum: {
        PT: "Número do Certificado de Calibração",
        US: "Calibration Certificate Number",
        ES: "Número del Certificado de Calibración"
    },
    
    calibDate: {
        PT: "Data de Calibração",
        US: "Calibration Date",
        ES: "Fecha de Calibración"
    },
    
    calibDeadline: {
        PT: "Prazo de Calibração",
        US: "Calibration Deadline",
        ES: "Fecha Límite de Calibración"
    },
    
    desc: {
        PT: "Descrição",
        US: "Description",
        ES: "Descripción"
    },
    
    id: {
        PT: "Identificação",
        US: "ID",
        ES: "Identificación"
    },
    
    scale: {
        PT: "Escala",
        US: "Scale",
        ES: "Escala"
    },
    
    precision: {
        PT: "Precisão",
        US: "Precision",
        ES: "Precisión"
    },
    
    SecurityValves: {
        PT: "Válvulas de Segurança",
        US: "Security Valves",
        ES: "Válvulas de Seguridad"
    },
    
    main: {
        PT: "Principal",
        US: "Main",
        ES: "Principal"
    },
    
    secondary: {
        PT: "Secundário",
        US: "Secondary",
        ES: "Secundario"
    },
    
    relief: {
        PT: "Alívio",
        US: "Relief",
        ES: "Alivio"
    },
    
    local: {
        PT: "Local",
        US: "Local",
        ES: "Local"
    },
    
    size: {
        PT: "Tamanho",
        US: "Size",
        ES: "Tamaño"
    },
    
    model: {
        PT: "Modelo",
        US: "Model",
        ES: "Modelo"
    },
    
    capacity: {
        PT: "Capacidade",
        US: "Capacity",
        ES: "Capacidad"
    },
    
    openPressure: {
        PT: "Pressão de Abertura",
        US: "Opening Pressure",
        ES: "Presión de Apertura"
    },
    
    Classification: {
        PT: "Classificação",
        US: "Classification",
        ES: "Clasificación"
    },
    
    maxPressure: {
        PT: "Pressão Máxima",
        US: "Maximum Pressure",
        ES: "Presión Máxima"
    },
    
    value: {
        PT: "Valor",
        US: "Value",
        ES: "Valor"
    },
    
    risk: {
        PT: "Risco",
        US: "Risk",
        ES: "Riesgo"
    },
    
    vessel: {
        PT: "Vaso",
        US: "Vessel",
        ES: "Vaso"
    },
    
    Results: {
        PT: "Resultados",
        US: "Results",
        ES: "Resultados"
    },

    selectOnlyOneReport: {
        PT: "Selecione Apenas 1 Relatório",
        US: "Select Only One Report",
        ES: "Seleccione Solo 1 Informe"
    },    
    
}

export default lang;
