import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FlagSelector from '../../../components/FlagSelector'
import Sidebar from '../../../components/Sidebar'
import { useGlobalContext } from '../../../contexts/GlobalContext'
import lang from './lang'
import './styles.css'
import { faAngleLeft, faSearch } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import CustomDropBox from '../../../components/CustomDropBox'
import { useState, useRef } from 'react'
import { getDataBaseCondition, getDatabaseRows, getImagePaths } from '../../../utils/database'
import { sleep, useInitialCheck } from '../../../utils'
import ScrollView from '../../../components/ScrollView'
import { Table, TableRow, TableCell } from '../../../components/Table'
import CustomDonutChart from '../../../components/Graphs/CustomDonutChart'
import CustomBarChart from '../../../components/Graphs/CustomBarChart'
import axios from 'axios'
import CustomModal from '../../../components/Modal'
import AsyncButton from '../../../components/AsyncButton'
import html2canvas from 'html2canvas';
import CustomTextField from '../../../components/CustomTextField'
import mapboxgl from 'mapbox-gl';
import { cursorTo } from 'readline'


mapboxgl.accessToken = "pk.eyJ1Ijoia2xhdXNzbWFyY2hpIiwiYSI6ImNsbGNsb245dzAyNXkzbm9iZzJ4emt2eWsifQ.HznsI_vVJ-lWe9swvbfT-Q";


export function CorrosionPage(){
    const {globalOptions, setGlobalOptions} = useGlobalContext()
    const currLang = globalOptions.language
    const navigate = useNavigate()

    const [active, setActive] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showData, setShowData] = useState(false)
    const [exportView, setExportView] = useState(false)
    const [emailModalOpen, setEmailModalOpen] = useState(false)
    const [locationImage, setLocationImage] = useState<any>([])
    const [companyImg, setCompanyImg] = useState<string>('')
    const [totalGraphsGenerated, setTotalGraphsGenerated] = useState(0);

    const [companies, setCompanies] = useState<any>([])
    const [plants, setPlants] = useState<any>([])
    const [selectedCompany, setSelectedCompany] = useState<any>({})
    const [selectedPlant, setSelectedPlant] = useState<any>({})

    const [deadlines, setDeadlines] = useState<any>([])
    const [yearDeadline, setYearDeadline] = useState<any>([])
    const [monthDeadline, setMonthDeadline] = useState<any>([])
    const [selectedYear, setSelectedYear] = useState<any>([])
    const [selectedMonth, setSelectedMonth] = useState<any>([])

    const [inspections, setInspections] = useState<any>([])
    const [processes, setProceses] = useState<any>([])
    const [equipments, setEquipments] = useState<any>([])

    useInitialCheck(importCompanies)
    useInitialCheck(importPlants, [selectedCompany])
    useInitialCheck(handleDataChange, [selectedCompany, selectedPlant])
    
    async function importCompanies(){
        const response = await getDatabaseRows('companies')
        setCompanies(response)
    }

    async function importPlants(){
        if(!selectedCompany.name)
            return

        const response = await getDataBaseCondition('plants', 'company', selectedCompany.cnpj)
        setPlants(response)
    }

    function handleDataChange(){
        if(!selectedCompany.name || !selectedPlant.name){
            setActive(false)
            setShowData(false)
            return
        }

        setActive(true)
    }

    function isYearEmpty() {
        if(!selectedYear[0])
            return true

        return false
    }

    async function handleButtonClick(){
        await sleep(700)
        let response
        
        response = await getDataBaseCondition('inspections', 'plant', selectedPlant.plant_id) 
        //alert((response.filter((it: any) => it.type === 'c')))
        setInspections(response.filter((it: any) => it.type === 'c'))

        const formattedDeadlines = response.map((it: any) => ({
            label: it.deadline,
            name: it.deadline,
            year: new Date(it.deadline).getFullYear().toString(),
            month: (new Date(it.deadline).getMonth() + 1).toString().padStart(2, '0')
        }));
        
        setDeadlines(formattedDeadlines);
        
        const uniqueYears = Array.from(new Set(formattedDeadlines.map((item: any) => item.year)));
        const uniqueMonths = Array.from(new Set(formattedDeadlines.map((item: any) => item.month)));
        
        setYearDeadline(uniqueYears.map(year => ({ label: year, name: year })));
        setMonthDeadline(uniqueMonths.map(month => ({ label: month, name: month })));

        response = await getDataBaseCondition('equipments', 'plant', selectedPlant.plant_id)
        setEquipments(response)

        response = await getDataBaseCondition('processes', 'plant', selectedPlant.plant_id)
        setProceses(response)
        setShowData(true)
        //importImages(response[0])

        const images: any = await getImagePaths(companies[0].img_path)
        const image: any  = images.length > 0 ? images[0] : ''
        setCompanyImg(image)

        importImages(selectedPlant)
    }

    async function importImages(target: any) {
        if(!target)
            return

        //alert(JSON.stringify(target))
        const images = await getImagePaths(target.img_path)

        if(!images || images.length == 0)
            return
        
        setLocationImage(images)
    }  

    let processDataList: any[] = [];

    function renderGraphs(){
        let rows: any = [];
        for(let i=0; i<processes.length; i+=4){
            let columns: any = [];

            for(let j=0; j<4; j++){
                if(i+j >= processes.length)
                    continue;
                
                const process = processes[i+j];
                const targetEquipments = equipments.filter((item: any) => item.process == process.process_id);

                let targetInspections: any = [];
                for(let equip of targetEquipments){
                    const target = inspections.filter((item: any) => (item.equipment == equip.equipment_id) && (item.approved == 1));
                    targetInspections = [...targetInspections, ...target];
                }
                
                const lastInspection = getLastInspection(targetInspections);
                const lastRelatory   = lastInspection ? JSON.parse(lastInspection.relatory) : null;
                //const corrosion      = lastRelatory   ? lastRelatory?.SubstrateEvaluation?.corrosion + '%' : '0%';

                const targetReports = targetInspections.map((item: any) => JSON.parse(item?.relatory));
                const sumS4   = targetReports.filter((item: any) => item?.SubstrateEvaluation?.corrosionSeverity[currLang]?.includes('S4')).length;
                const sumS3   = targetReports.filter((item: any) => item?.SubstrateEvaluation?.corrosionSeverity[currLang]?.includes('S3')).length;
                const sumS2   = targetReports.filter((item: any) => item?.SubstrateEvaluation?.corrosionSeverity[currLang]?.includes('S2')).length;
                const sumS1   = targetReports.filter((item: any) => item?.SubstrateEvaluation?.corrosionSeverity[currLang]?.includes('S1')).length;
                const allNull = (sumS4 == 0) && (sumS3 == 0) && (sumS2 == 0) && (sumS1 == 0);
                
                const corrosionSum = targetReports.reduce((sum: number, report: any) => {
                    const corrosionValue = parseFloat(report?.SubstrateEvaluation?.corrosion) || 0;
                    return sum + corrosionValue;
                }, 0);
                
                const corrosion = corrosionSum + '%';

                processDataList.push({
                    id: `graph-${i + j}`,
                    processName: process.name,
                    corrosion: corrosion
                });

                let data: any = [];

                if(sumS4 != 0)
                    data.push({name: lang.S4[currLang], value: sumS4, color: 'red'});
                
                if(sumS3 != 0)
                    data.push({name: lang.S3[currLang], value: sumS3, color: 'orange'});
                
                if(sumS2 != 0)
                    data.push({name: lang.S2[currLang], value: sumS2, color: 'yellow'});

                if(sumS1 != 0)
                    data.push({name: lang.S1[currLang], value: sumS1, color: 'green'});
                
                if(!allNull){
                    columns.push(
                        <div className='column' >
                            <div style={{color: 'white'}}>
                                {corrosion}
                            </div>

                            <div style={{padding: '.7rem'}}/>

                            <div style={{height: '100%', width: '100%'}} id={`graph-${i + j}`}>
                                <CustomDonutChart
                                    data={data}
                                    hasData={!allNull}
                                />
                            </div>

                            <div style={{padding: '.7rem'}}/>

                            <div style={{color: 'white'}}>
                                {process.name}
                            </div>
                        </div>
                    );
                }                
            }
            
            if (columns.length > 0) {
                rows.push(
                    <div className='row' key={`row-${i}`}>
                        {columns}
                    </div>
                );
            }
        }

        return (
            <div className='GraphsContainer'>
                {rows.map((row: any, index: number) => (
                    <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                        {row}
                        {(index == rows.length - 1) ? <div/> : <div className='LineRow'/>}
                    </div>
                ))}
            </div>
        );
    }

    async function getGraphImageBase64(index: number): Promise<string | null> {
        try {
            const graphElement = document.getElementById(`graph-${index}`);
            if (!graphElement) return null; // Retorna null se o elemento não for encontrado
    
            const canvas = await html2canvas(graphElement);
            const imgData = canvas.toDataURL('image/png');
            return imgData;
        } catch (error) {
            console.error('Error capturing graph:', error);
            return null;
        }
    }
    
    async function getAllGraphImagesBase64() {
        const graphImages = [];
        let index = 0;
    
        while (true) {
            const graphImage = await getGraphImageBase64(index);
            if (graphImage === null) break;
            graphImages.push(graphImage);
            index++;
        }
    
        return graphImages;
    }
    
    function getLastInspection(data: any){
        let last_inspection = null
        let target = (data.length == 0) ? null : data[0]

        for(let x=0; x<data.length; x++){
            const insp = data[x]
            const date = new Date(insp.deadline)

            if(!last_inspection || date > last_inspection){
                last_inspection = date
                target = insp
            }
        }

        return target
    }

    function renderTable(){
        return (
            <Table>
                <TableRow>
                    <TableCell>{lang.equipment[currLang]}</TableCell>
                    <TableCell>{lang.process[currLang]}</TableCell>
                    <TableCell>{lang.numinsp[currLang]}</TableCell>
                    <TableCell>{lang.totalCorrosion[currLang]}</TableCell>
                    <TableCell>{lang.degradation[currLang]}</TableCell>
                    <TableCell>{lang.severity[currLang]}</TableCell>
                    <TableCell>{lang.materialLoss[currLang]}</TableCell>
                    <TableCell>{lang.observations[currLang]}</TableCell>
                </TableRow>

                {equipments.map((item: any) => {
                    const targetInspections = inspections.filter((insp: any) => (item.equipment_id == insp.equipment) && (insp.approved == 1) && (insp.type === 'c'))
                    const lastInspection = getLastInspection(targetInspections)
                    const relatory = !lastInspection ? null : JSON.parse(lastInspection.relatory)

                    //inspections.forEach((insp: any) => alert((item.equipment_id == insp.equipment)))
                    //alert(JSON.stringify(relatory.CoatingEvaluation))
                    const sum = targetInspections.length
                    const severity = !relatory ? 'N/A'  : relatory?.SubstrateEvaluation?.corrosionSeverity[currLang]   
                    const corrosion = !relatory ? 'N/A' : relatory?.SubstrateEvaluation?.corrosion + '%'
                    //const materialLoss = !relatory ? 'N/A' : relatory?.CoatingEvaluation?.coatingDegradation[currLang]
                    const materialLoss = !relatory?.CoatingEvaluation?.coatingDegradation ? 'N/A' : relatory?.CoatingEvaluation?.coatingDegradation[currLang]
                    const degradation = !relatory ? 'N/A' : relatory?.CoatingEvaluation?.degradationClass

                    const obs = !relatory?.CoatingEvaluation?.observations ? 'N/A' : relatory?.CoatingEvaluation?.observations + ', ' + relatory?.SubstrateEvaluation?.observations
                    const targetProcess = processes.filter((proc: any) => proc.process_id == item.process)
                    
                    return (
                        <TableRow>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{(targetProcess.length == 0) ? 'N/A' : targetProcess[0].name}</TableCell>
                            <TableCell>{sum}</TableCell>
                            <TableCell>{corrosion}</TableCell>
                            <TableCell>{degradation}</TableCell>
                            <TableCell>{severity}</TableCell>
                            <TableCell>{materialLoss}</TableCell>
                            <TableCell>{obs}</TableCell>
                        </TableRow>
                    )
                })}
            </Table>
        )
    }

    function renderBarData(){
        const targetInspections = inspections.filter((item: any) => item.plant == selectedPlant.plant_id)
        const targetReports = targetInspections.map((item: any) => JSON.parse(item.relatory ? item.relatory : '{}'))
        const recPoints = targetReports.map((item: any) => (item?.Recommendations?.points) ? (item?.Recommendations?.points) : '')

        let defaultData = [
            { 
                key: 'reinspect_after', 
                label: lang.reinspectAfter[currLang], 
                one_year: recPoints.filter((points: any) => points["opt"] === 'opt1' && points[currLang].includes('1')).length, 
                two_years: recPoints.filter((points: any) => points["opt"] === 'opt1' && points[currLang].includes('2')).length,
                three_years: recPoints.filter((points: any) => points["opt"] === 'opt1' && points[currLang].includes('3')).length,
            },
            { 
                key: 'localized_repairs', 
                label: lang.repairUntil[currLang], 
                one_year: recPoints.filter((points: any) => points["opt"] === 'opt2' && points[currLang].includes('1')).length, 
                two_years: recPoints.filter((points: any) => points["opt"] === 'opt2' && points[currLang].includes('2')).length,
                three_years: recPoints.filter((points: any) => points["opt"] === 'opt2' && points[currLang].includes('3')).length,
            },
            { 
                key: 'substitution', 
                label: lang.substitution[currLang], 
                one_year: recPoints.filter((points: any) => points["opt"] === 'opt3' && points[currLang].includes('1')).length, 
                two_years: recPoints.filter((points: any) => points["opt"] === 'opt3' && points[currLang].includes('2')).length,
                three_years: recPoints.filter((points: any) => points["opt"] === 'opt3' && points[currLang].includes('3')).length,
            },
        ];
        
        let defaultBars = [
            {key: 'one_year',    label: '1 ' + lang.year[currLang],  color: '#E1A58E'},
            {key: 'two_years',   label: '2 ' + lang.year[currLang] + 'S', color: '#F36934'},
            {key: 'three_years', label: '3 ' + lang.year[currLang] + 'S', color: '#F24000'},
        ]

        return (
            <div style={{display: 'flex', width: '100%', height: '55vh'}}>
                <CustomBarChart data={defaultData} bars={defaultBars}/>
            </div>
        )
    }

    function renderData(){
        return (
            <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                <div style={{padding: '.7rem'}}/>
                
                <div className='TextStyle' style={{textAlign: 'center'}}>
                     % {lang.ofcorrosion[currLang]} - {selectedPlant.name}
                </div>
                    
                <div style={{padding: '.7rem'}}/>
                {renderTable()}

                <div style={{padding: '.7rem'}}/>

                <div className='TextStyle' style={{textAlign: 'center'}}>
                    {lang.graphs[currLang]} - {selectedPlant.name}
                </div>

                <div style={{padding: '.7rem'}}/>
                {renderGraphs()}

                <div style={{padding: '1rem'}}/>
                {renderBarData()}
            </div>
        )
    }

    async function urlImageToBase64(url: string) {
        try {
            const response = await axios({ 
                method: 'get', 
                url: url, 
                responseType: 'blob'
            })

            const blob = await response.data;

            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        } catch (error) {
            console.error('Error fetching the image:', error);
            return null;
        }
    }

    function openModal () {
        setEmailModalOpen(!emailModalOpen)
    }

    function getCoords() {
        let plantCoords = {
            lat: selectedPlant.lat,
            long: selectedPlant.long
        };
        
        let processesCoords = processes.map((process: any) => ({
            lat: process.lat,
            long: process.long
        }));
        
        let equipmentsCoords = equipments.map((equipment: any) => ({
            lat: equipment.lat,
            long: equipment.long
        }));
        return { plantCoords, processesCoords, equipmentsCoords }
    }

    function calculateBoundingBox(coordsArray: { lat: number; long: number }[]) {
        const lats = coordsArray.map(coord => coord.lat);
        const longs = coordsArray.map(coord => coord.long);
        
        const minLat = Math.min(...lats);
        const maxLat = Math.max(...lats);
        const minLong = Math.min(...longs);
        const maxLong = Math.max(...longs);
        
        return { minLat, maxLat, minLong, maxLong };
    }
    
    function calculateCenter(bbox: { minLat: number; maxLat: number; minLong: number; maxLong: number }) {
        const lat = (bbox.minLat + bbox.maxLat) / 2;
        const long = (bbox.minLong + bbox.maxLong) / 2;
        
        return { lat, long };
    }
    
    function calculateZoom(bbox: { minLat: number; maxLat: number; minLong: number; maxLong: number }, mapWidth: number, mapHeight: number) {
        const latDiff = bbox.maxLat - bbox.minLat;
        const longDiff = bbox.maxLong - bbox.minLong;
        
        const WORLD_DIM = { width: mapWidth, height: mapHeight };
        const ZOOM_MAX = 21;
    
        const latZoom = Math.log2(WORLD_DIM.height / latDiff);
        const longZoom = Math.log2(WORLD_DIM.width / longDiff);
    
        return Math.min(latZoom, longZoom, ZOOM_MAX);
    }
    
    function getOptimalZoom() {
        const { plantCoords, processesCoords, equipmentsCoords } = getCoords();
        
        const allCoords = [plantCoords, ...processesCoords, ...equipmentsCoords];
        
        const bbox = calculateBoundingBox(allCoords);
        const center = calculateCenter(bbox);
        
        // Map size in pixels
        const mapWidth = 500;
        const mapHeight = 300;
        
        const zoom = calculateZoom(bbox, mapWidth, mapHeight);
        
        return { center, zoom };
    }
    
    function generateMapboxUrl(plantCoords: any, processesCoords: any, equipmentCoords: any) {
        let features = [];
    
        // Adiciona o marcador da planta
        features.push({
            type: "Feature",
            properties: { "marker-color": "#FF0000" }, // Vermelho para a planta
            geometry: {
                type: "Point",
                coordinates: [parseFloat(plantCoords.long), parseFloat(plantCoords.lat)]
            }
        });
    
        // Adiciona os marcadores dos processos
        processesCoords.forEach((process: any) => {
            features.push({
                type: "Feature",
                properties: { "marker-color": "#FFFF00" }, // Amarelo para processos
                geometry: {
                    type: "Point",
                    coordinates: [parseFloat(process.long), parseFloat(process.lat)]
                }
            });
        });
        
        // Adiciona os marcadores dos equipamentos
        equipmentCoords.forEach((equipment: any) => {
            features.push({
                type: "Feature",
                properties: { "marker-color": "#0000FF" }, // Azul para equipamentos
                geometry: {
                    type: "Point",
                    coordinates: [parseFloat(equipment.long), parseFloat(equipment.lat)]
                }
            });
        });
    
        // Converte as features para um objeto FeatureCollection
        let geoJson = {
            type: "FeatureCollection",
            features: features
        };
    
        // Log do GeoJSON para validação
        console.log("GeoJSON:", JSON.stringify(geoJson));
    
        // Converte o objeto GeoJSON para uma string codificada em URL
        let geoJsonString = encodeURIComponent(JSON.stringify(geoJson));
        
        // Centraliza o mapa nas coordenadas da planta
        //let centerCoords = `${plantCoords.long},${plantCoords.lat}`;
        
        const { center, zoom } = getOptimalZoom();
        let centerCoords = `${center.long},${center.lat}`
        let apropZoom = `${zoom}`
        // Gera a URL do Mapbox
        let url = `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/static/geojson(${geoJsonString})/${centerCoords},${apropZoom}/1000x600?access_token=pk.eyJ1IjoiamVhbnZyMDk4IiwiYSI6ImNscGQyc2hnejBwaTcya21tY2w3emJpOHAifQ.Aa96RubapYbo0gdZbBUxjw`;
    
        console.log("Generated URL:", url);
    
        return url;
    }    
    
    async function captureMap() {
        let { plantCoords, processesCoords, equipmentsCoords } = getCoords()
        let mapboxUrl = generateMapboxUrl(plantCoords, processesCoords, equipmentsCoords);
        if (mapboxUrl)
            return(mapboxUrl)
        else
            return('')
    }

    async function exportData() {
        const locationImg = []
        let slyear = selectedYear.map((it: any) => it.name)

        let dataFilteredInspections = inspections.filter((inspection: any) => {
            const inspectionDate = new Date(inspection.deadline);
            const year = inspectionDate.getFullYear().toString();

            return slyear == year;
        });

        let approvedInspections = dataFilteredInspections.filter((item: any) => item.approved == 1)

        //alert(JSON.stringify(year))
        //alert(filteredInspections)

        const graphImage = await getAllGraphImagesBase64();
        
        for (let img of locationImage.filter((it: any) => it.includes('_1') || it.includes('locality'))) {
            const base64 = await urlImageToBase64(img)
            locationImg.push(base64)
        }

        
        const companyLogo = await urlImageToBase64(companyImg)

        const relatoryData = {
            ...approvedInspections
        }

        let completeImage = await urlImageToBase64(await captureMap())

        const generalData = {
            currLang: currLang,
            company: selectedCompany.name,
            plant: selectedPlant.name,
            processes: processes,
            equipments: equipments,
            graphImg: graphImage,
            graphData: processDataList,
            companyLogo: companyLogo,
            locationImg: locationImg,
            completeImg: completeImage,
            year: slyear,
        }

        sessionStorage.setItem('relatoryData', JSON.stringify(relatoryData))
        sessionStorage.setItem('generalData', JSON.stringify(generalData))

        setExportView(true)
    }

    return (
        <div className='MainContainer'>
            <FlagSelector/>
            <Sidebar/>
            <div className='SideBarCompensation'/>

            <div className='CentralBlock'>
                <ScrollView className='CorrosionContainer'>
                    <div className='HeaderContainer'>
                        <div style={{display: 'flex', flexDirection: 'row', width: '50%'}}>
                            <FontAwesomeIcon 
                                icon={faAngleLeft}
                                color='white'
                                fontSize='1.5rem'
                                cursor='pointer'
                                onClick={() => navigate(-1)}
                            />

                            <div style={{width: '2%'}}/>

                            <div className='TextStyle'>{lang.corrosionRates[currLang]}</div>
                        </div>
                        
                        {showData && (
                            <>
                                <button 
                                    className="approveButton" 
                                    onClick={async () => openModal()}
                                    disabled={loading}
                                >{lang.export[currLang]}</button>
                              
                                <CustomModal modalOpen={emailModalOpen} className='filterModalContainer'>
                                    <div className='textsModalContainer'>
                                        <h1 className='PageModalTextContainer'>{lang.filter[currLang]}</h1>
                                    </div>
                                    <div style={{width: '65%'}}>
                                        <CustomDropBox
                                            options={yearDeadline}
                                            value={selectedYear.name}
                                            setValue={setSelectedYear}
                                            placeholder={lang.year[currLang]}
                                            key='label'
                                            callbackKey={null}
                                            multiSelection={true}
                                        />
                                    </div>

                                    <div style={{padding: '.7rem'}}/>

                                    <div style={{width: '65%'}}>
                                        <CustomDropBox
                                            options={monthDeadline}
                                            value={selectedMonth.name}
                                            setValue={setSelectedMonth}
                                            placeholder={lang.month[currLang]}
                                            key='label'
                                            callbackKey={null}
                                            multiSelection={true}
                                        />
                                    </div> 
                                    <div className='filterModalButtonWrapper'>
                                        <AsyncButton
                                            active={loading}
                                            text={lang.close[currLang]}
                                            className='PageModalButtonContainer'
                                            onClick={() => openModal()}
                                            loading={loading}
                                        /> 

                                        <AsyncButton
                                            active={loading || isYearEmpty()}
                                            text={lang.confirm[currLang]}
                                            className='PageModalButtonContainer'
                                            onClick={(loading || !active) ? () => null : async () => {
                                                setLoading(true);
                                                await exportData();
                                                setLoading(false)
                                            }}
                                            loading={loading}
                                        />
                                    </div>

                                </CustomModal>
                                {exportView && (  
                                <div className="popup" style={{boxShadow: '0 0 30px rgba(0, 0, 0, 0.5)', display: "none"}}>
                                <iframe
                                    src={'templateGeneral/index.html'}
                                    style={{ 
                                        width: '100%', 
                                        height: '40px',  
                                        margin:  0,
                                        padding: 0,
                                        border:  0
                                    }}
                                    title="Pré-visualização do PDF"
                                ></iframe>
                                </div>)}
                            </>
                        )

                        }
                    </div>

                    <div style={{padding: '1rem'}}/>

                    <div style={{
                            display: 'flex', 
                            flexDirection: 'column', 
                            alignItems: 'center',
                        }}
                    >
                        <div style={{width: '80%'}}>
                            <CustomDropBox
                                options={companies}
                                value={selectedCompany.name}
                                setValue={setSelectedCompany}
                                placeholder={lang.selectCompany[currLang]}
                                key='label'
                                callbackKey={null}
                            />
                        </div>

                        <div style={{padding: '.7rem'}}/>

                        <div style={{width: '80%'}}>
                            <CustomDropBox
                                options={plants}
                                value={selectedPlant.name}
                                setValue={setSelectedPlant}
                                placeholder={lang.selectPlant[currLang]}
                                key='label'
                                callbackKey={null}
                            />
                        </div>

                        <div style={{padding: '.7rem'}}/>
                        
                        <div
                            className='buttonCorrosion'
                            style={(loading || !active) ? {cursor: 'not-allowed', opacity: .7} : {}}
                            onClick={(loading || !active) ? () => null : async () => {
                                setLoading(true);
                                await handleButtonClick();
                                setLoading(false)
                            }}
                        >
                            {loading ?
                                (<div className='loadingCircle'/> )
                                : (
                                <div className='buttonCorrosion' style={{width: '100%', cursor: active ? 'pointer' : 'not-allowed'}}>
                                    <div style={{color: 'white'}}>{lang.consult[currLang]}</div>
                                </div>
                                )
                            }
                        </div>

                        {showData && renderData()}
                    </div>
                </ScrollView>
            </div>
        </div>
    )
}