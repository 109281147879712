import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import FlagSelector from "../../../../../components/FlagSelector";
import { CustomModalPage } from "../../../../../components/Modal";
import Sidebar from "../../../../../components/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomDropBox from "../../../../../components/CustomDropBox";
import CustomTextField from "../../../../../components/CustomTextField";
import { useGlobalContext } from "../../../../../contexts/GlobalContext";
import lang from '../../lang'
import { useEffect, useState } from "react";

type SecondPageProps = {
    selectedEquipment: any;
    technicalData: any;
    setTechnicalData: (techData: any) => void;
    edit: boolean;
    modalOpen: boolean;
    setPage: (pageNum: number) => void;
}

export function SecondPage({ 
    technicalData, 
    setTechnicalData, 
    edit, 
    modalOpen,
    selectedEquipment,
    setPage
}: SecondPageProps) {
    const [canGoToNextPage, setCanGoToNextPage] = useState(false)
    const [fieldChanged, setFieldChanged] = useState(false)

    const { globalOptions } = useGlobalContext()
    const currLang = globalOptions.language

    useEffect(() => { 
        const isValid = Object.values(technicalData).every(val => val && val !== undefined && val !== '')
        setCanGoToNextPage(isValid)
    }, [fieldChanged])
    
    function handleTechnicalDataChange(keyName: string, value: any, isId: boolean=false) {
		const newItem = technicalData
        const params = JSON.parse(selectedEquipment.equipment_type.parameters)

        for (let lang in newItem) {
            // Caso seja uma lista de opções, coloca o valor recebido correspondente (pelo id) em cada língua
            if (isId) 
                newItem[lang][keyName]['value'] = params[lang][keyName]['info'].find((i: any) => i.id === value).name
            else
                newItem[lang][keyName]['value'] = value
        }

		setTechnicalData(newItem)
        setFieldChanged(!fieldChanged)
	}
    
    function renderInputs(equipmentType: any) {
		const parameters = (JSON.parse(equipmentType.parameters))[currLang]
		const sanitizedParams = Object.keys(parameters).map((key: string) => {
			const param = parameters[key]
			if (param.options) {
				const sanitizedInfo = param.info.map((i: any) => ({ name: i.name, id: i.id }))
				return { ...param, info: sanitizedInfo, keyName: key }
			}
			return { ...param, keyName: key }
		})

		return (
			<div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
				{
					sanitizedParams.map((param: any) => {
						if (param.options) {
							return (
								<div key={param.name} className='input-container-equipment' >
									<CustomDropBox
										options={param.info}
										placeholder={param.name}
										key={param.name}
										value={technicalData[currLang][param.keyName]['value']}
										setValue={(value: any) => handleTechnicalDataChange(param.keyName, value.id, true)}
									/>
									<div style={{ padding: '.6rem' }} />
								</div>
							)
						} 
						else {
							return (
								<div key={param.name} className='input-container-equipment'>
									<CustomTextField
										placeholder={param.name}
										value={technicalData[currLang][param.keyName]['value']}
										setValue={(value: any) => handleTechnicalDataChange(param.keyName, value)}
									/>
									<div style={{ padding: '.6rem' }} />
								</div>
							);
						}
					})
				}
			</div>
		);
	}

    return (
        <div className="MainContainer">
            <CustomModalPage
                title={lang.equipmentRegistered[currLang]}
                subtitle={lang.wantToRequestInspection[currLang]}
                yesPage='/Request?insert'
                noPage='/Home'
                yesText={lang.yes[currLang]}
				noText={lang.no[currLang]}
                modal={modalOpen}
            />

            <div className='SideBarCompensation' />

            <div className='CentralBlock' style={{ height: '100vh' }}>
                <FlagSelector />
                <Sidebar activePage='Register' />

                <div className="RegisterMainContainer">
                    <div style={{ padding: '.7rem' }} />

                    <div className="iconContainer" style={{ width: '100%' }}>
                        <div
                            className='iconLeft' style={{ alignSelf: 'center' }}
                            onClick={() => setPage(1)}
                        >
                            <FontAwesomeIcon icon={faAngleLeft} title="Empresas já cadastradas" />
                        </div>

                        <div style={{ padding: '10px' }} />

                        <div className='clientText'>{edit ? lang.editEquipment[currLang] : lang.registerEquipment[currLang]}</div>

                        <div style={{ flex: 1 }} />
                    </div>

                    <div style={{ padding: '.7rem' }} />

                    <div className='text-equipment2'>{lang.thecnicData[currLang]}</div>

                    {renderInputs(selectedEquipment.equipment_type)}
                    <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>
                        <button
                            className='button-equipment-new'
                        onClick={() => setPage(3)}
                            style={!canGoToNextPage ? { opacity: 0.8, cursor: 'not-allowed' } : {}}
                            disabled={!canGoToNextPage}
                        >
                            {lang.next[currLang]}
                        </button>
                    </div>


                </div>
            </div>
        </div>
    )
}