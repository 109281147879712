import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleLeft, faAngleRight, faAngleUp, faCamera, faFileExcel, faFileExport, faX } from "@fortawesome/free-solid-svg-icons";
import 'jspdf-autotable';
import ExcelJS, { BorderStyle } from 'exceljs';
import axios from 'axios'

import './styles.css'
import FlagSelector from "../../../components/FlagSelector";
import Sidebar from "../../../components/Sidebar";
import { getStoredJson, saveJsonData, sendNotification, sleep, useInitialCheck } from "../../../utils";
import { editDatabaseByCondition, getDataBaseCondition, getDatabaseRows, getImagePaths } from "../../../utils/database";
import { formatTimestamp } from "../../../components/DatePicker";
import logoImage from '../../../assets/img/logo.png';
import { useGlobalContext } from "../../../contexts/GlobalContext";
import lang from "./lang";
import { NotificationTypes, getNormalizedInspectorLang } from "../../../utils/consts";
import ScrollView from "../../../components/ScrollView";
import CustomModal from "../../../components/Modal";
import AsyncButton from "../../../components/AsyncButton";
import surplusFullLogo from '../../../assets/img/logoSurplus.png'

function renderCameraIcon(width: string, height: string, img: any = '') {
    if (!img || img == '')
        return (
            <div className='cameraContainer' style={{ width: width, height: height }}>
                <FontAwesomeIcon icon={faCamera} className="cameraIcon" />
            </div>
        )

    return (<img className='cameraContainer' style={{ width: width, height: height }} src={img} alt="" />)
}

export function ExplorePage() {
    const user = getStoredJson('user', {})

    const [selectedOption, setSelectedOption] = useState('visualInspection')
    const [isSmallSizeWindow, setIsSmallSizeWindow] = useState(window.innerWidth < 760)

    const stored: any = getStoredJson('pendingInspection')
    const relatory: any = stored.relatory ? JSON.parse(stored.relatory) : {}
    console.log(JSON.stringify(relatory))
    const isEdit: boolean = Object.keys(relatory).length == 0
    const inspection: any = renderData(stored, relatory)

    const [isLoading, setIsLoading] = useState(true)
    const [equipment, setEquipment] = useState<string>('')
    const [company, setCompany] = useState<string>('')
    const [companyID, setCompanyID] = useState<string>('')
    const [companyImg, setCompanyImg] = useState<string>('')
    const [plant, setPlant] = useState<string>('')
    const [process, setProcess] = useState<string>('')
    const [images, setImages] = useState<any>([])
    const [imagesDesc, setImagesDesc] = useState<any>([])
    const [eqpImages, setEqpImages] = useState<any>([])
    const [fullEquipment, setFullEquipment] = useState<any>([])
    const [showPDFPopup, setShowPDFPopup] = useState(false)
    const [multipleSelected, setMultipleSelected] = useState(false)
    const [showRetPopup, setShowRetPopup] = useState(false)
    const [isRet, setIsRet] = useState(false)
    const [retText, setRetText] = useState<string>('')
    const [exportLoading, setExportLoading] = useState<boolean>(false)
    const [exportButton, setExportButton] = useState<boolean>(false)
    const [loadingApprove, setLoadingApprove] = useState<boolean>(false)
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [paintingPopup, setPaintingPopup] = useState(false)
    const [categories, setCategories] = useState<string[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [selectedDay, setSelectedDay] = useState<string | null>(null);

    const { globalOptions, setGlobalOptions } = useGlobalContext()
    const currLang = globalOptions.language
    const navigate = useNavigate()
    
    function updateScreenSize() {
        setIsSmallSizeWindow(window.innerWidth < 760)
    }

    useEffect(() => {
        importInfo()
        setIsLoading(false)
    }, [])

    useEffect(() => {importImages()}, [fullEquipment])


    useEffect(() => {
        window.addEventListener('resize', updateScreenSize)
        return () => window.removeEventListener('resize', updateScreenSize)
    })

    const data: any = [
        [lang.createdOn[currLang], formatTimestamp(inspection.deadline)],
        [lang.company[currLang], company],
        [lang.plant[currLang], plant],
        [lang.process[currLang], process],
        [lang.inspectorName[currLang], stored?.inspector],
        [lang.equipment[currLang], equipment],
        [lang.lastInspection[currLang], formatTimestamp(inspection?.last_modified_at)],
        [lang.itemDescription[currLang], 'Descrição aqui'],
        [lang.equipmentName[currLang], equipment],
        [lang.substrate[currLang], inspection.RepairData?.substrate],
        [lang.temperature[currLang], inspection.RepairData?.temperature],
        [lang.heightWork[currLang], inspection?.RepairData?.heightWork],
        [lang.insulation[currLang], inspection?.RepairData?.insulation],
        [lang.points[currLang], 'Substituição ou reparação em até 2 anos'],
        [lang.repairType[currLang], 'Localizado'],
        [lang.access[currLang], 'Andaimes'],
        [lang.repairHeight[currLang], '> 10 metros'],
    ]

    async function urlImageToBase64(url: string) {
        try {
            const response = await axios({ 
                method: 'get', 
                url: url, 
                responseType: 'blob'
            })

            const blob = await response.data;

            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        } catch (error) {
            console.error('Error fetching the image:', error);
            return null;
        }
    }

    async function importInfo() {
        let response, target

        response = await getDataBaseCondition('companies', 'cnpj', inspection.company)
        target   = response.length > 0 ? response[0] : {}
        setCompany(target.name)
        setCompanyID(target.cnpj)

        const images: any = await getImagePaths(target.img_path)
        const image: any  = images.length > 0 ? images[0] : ''
        setCompanyImg(image)

        response = await getDataBaseCondition('plants', 'plant_id', inspection.plant)
        target   = response.length > 0 ? response[0] : {}
        setPlant(target.name)

        response = await getDataBaseCondition('processes', 'process_id', inspection.process)
        target   = response.length > 0 ? response[0] : {}
        setProcess(target.name)

        response = await getDataBaseCondition('equipments', 'equipment_id', inspection.equipment)
        target   = response.length > 0 ? response[0] : {}

        setEquipment(target.name)
        setFullEquipment(target)

        setImagesDesc(stored.imgs_desc)
    }

    async function importImages() {
        if (!stored.id)
            return null
        
        const imgPath = `img/inspection/${stored.id}`
        const response = await getImagePaths(imgPath)
        
        setImages(response)

        if(!fullEquipment)
            return
        const images = await getImagePaths(fullEquipment.img_path)

        if(!images || images.length == 0)
            return
        
        setEqpImages(images)
        setExportButton(true)
    }


    function safeString(value: any){
        if(value == null || value == undefined)
            return 'N/A';

        if(typeof(value) == 'string' && value?.trim()?.length == 0)
            return 'N/A'
        
        return value.toString();
    }

    function handleSelectedOption(item: any) {
        setSelectedOption(item)
        setSelectedCategory(null)
    }

    function getSafeRelatoryCategory(category: any, relatory?: any) {
        if (relatory == 'Climate') {
            //alert(JSON.stringify(category))
            for (let field in category) {
                if (field == 'conditions')
                    category[field] = safeString(category[field][currLang])
            }
            return category
        }

        if (relatory == 'Ink') {
            category.forEach((element: any) => {
                for (let field in element) {
                    if (typeof element[field] === 'object') {
                        if(field != 'meshesRetentions')
                            element[field] = safeString(element[field][currLang])
                    }
                    else
                        element[field] = safeString(element[field])
                        
                }
                console.log('element: '+ JSON.stringify(element))
                return element
            });
            return category;
        }
        
        for (let field in category) {
            if (typeof category[field] === 'object') {
                if(field != 'meshesRetentions')
                    category[field] = safeString(category[field][currLang])
            }
            else
                category[field] = safeString(category[field])
        }
        return category
    }
    
    async function exportCorrosionData() {
        const targetEquipments = await getDataBaseCondition('equipments', 'equipment_id', inspection.equipment)
        const targetEquipment  = (targetEquipments.length > 0) ? targetEquipments[0] : {}
        const equipmentTypeId  = (targetEquipment.equipment_type) ? targetEquipment.equipment_type : 'N/A'

        const equipmentTypeRecords = await getDataBaseCondition('equipment_type', 'id', equipmentTypeId)
        const equipmentTypeRecord  = (equipmentTypeRecords.length > 0) ? equipmentTypeRecords[0] : {}
        
        const equipmentTypeName = equipmentTypeRecord?.name
        const equipmentCriticality = equipmentTypeRecord.criticality

        let locale = 'en-US'
        if (currLang === 'PT')
            locale = 'pt-BR'
        else if (currLang === 'ES')
            locale = 'es-ES'

        const date = inspection.deadline.split(' ')[0]
        const formattedDate = new Date(date).toLocaleDateString(locale)

        const stDate = inspection.start_date.split(' ')[0]
        const stFormattedDate = new Date(stDate).toLocaleDateString(locale)

        const lastDate = inspection.last_modified_at.split(' ')[0]
        const lastFormattedDate = new Date(lastDate).toLocaleDateString(locale)
        
        const companyLogo = await urlImageToBase64(companyImg)
        const surplusLogo = await urlImageToBase64(surplusFullLogo)

        const external_imgs = []
        const internal_imgs = []
        const eqpimg = []
     
        for (let img of images) {
            if (img.includes("external")) {
                const base64 = await urlImageToBase64(img);
                external_imgs.push(base64);
            } else if (img.includes("internal")) {
                const base64 = await urlImageToBase64(img);
                internal_imgs.push(base64);
            }
        }
        
        for (let img of eqpImages.filter((it: any) => it.includes('locality'))) {
            const base64 = await urlImageToBase64(img)
            eqpimg.push(base64)
        }

        const data = {
            ...inspection,
            plant: plant,
            company: company,
            process: process,
            equipment: equipment,
            inspector: stored.inspector,
            equipmentType: equipmentTypeName,
            criticality: equipmentCriticality,
            deadline: formattedDate,
            start_date: stFormattedDate,
            last_modified_at: lastFormattedDate,
            Recommendations: relatory.Recommendations,
            SectorFootage: relatory.SectorFootage,
            CoatingEvaluation: relatory.CoatingEvaluation,
            SubstrateEvaluation: relatory.SubstrateEvaluation,
            companyLogo: companyLogo,
            retifications: relatory?.retifications,
            RepairData: relatory?.RepairData,
            BasicData: relatory?.BasicData,
            internal_imgs: internal_imgs,
            external_imgs: external_imgs,
            equipmentImages: eqpimg,
            approved_by: stored.approved_by,
            relatory_id: stored.relatory_id,
            currLang: currLang,
            surplusLogo: surplusLogo,
        }
        //alert(JSON.stringify(data.BasicData))
        if (data.RepairData !== undefined)
            data.RepairData = getSafeRelatoryCategory(data.RepairData)
        else
            data.RepairData = {
                totalMeterage: 'N/A',
                repairPercent: 'N/A',
                meterage: 'N/A'
            }

        if (data.CoatingEvaluation !== undefined)
            data.CoatingEvaluation = getSafeRelatoryCategory(data.CoatingEvaluation)
        else
            data.CoatingEvaluation = {
                pollutantIncidency: 'N/A',
                mechanicDamage: 'N/A',
                coatingDegradation: 'N/A',
                coatingDetachment: 'N/A',
                degradationClass: 'N/A',
                degradationSeverity: 'N/A',
            }

        if (data.SubstrateEvaluation !== undefined)
            data.SubstrateEvaluation = getSafeRelatoryCategory(data.SubstrateEvaluation)
        else
            data.SubstrateEvaluation = {
                corrosionCharacteristics: 'N/A',
                agressive: 'N/A',
                underCorrosion: 'N/A',
                galvanicCorrosion: 'N/A',
                corrosionSeverity: 'N/A',
                corrosion: 'N/A',
                materialLoss: 'N/A',
                observations: 'N/A'
            }

        if (data.Recommendations !== undefined)
            data.Recommendations = getSafeRelatoryCategory(data.Recommendations)
        else
            data.Recommendations = {
                points: "N/A",
                time: "N/A",
                repairType: "N/A",
                access: "N/A",
                repairHeight: "N/A"
            }

        if (data.BasicData !== undefined)
            data.BasicData = getSafeRelatoryCategory(data.BasicData)
        else
            data.BasicData = {
                substrate: 'N/A',
                coating: 'N/A',
                temperature: 'N/A',
                insulation: 'N/A',
                PFP: 'N/A',
                heightWork: 'N/A',
                confinedSpace: 'N/A',
                criticality: 'N/A',                
            }
        
        sessionStorage.setItem('inspectionData', JSON.stringify(data))
    }

    async function exportPaintingData() {
        // Abrasive

        const abrasiveData = {
            ...relatory.Abrasive,
            Visual: relatory.Abrasive.Visual,
            Granulometry: relatory.Abrasive.Granulometry,
            Salts: relatory.Abrasive.Salts,
        }
       
        if (abrasiveData.Visual !== undefined)
            abrasiveData.Visual = getSafeRelatoryCategory(abrasiveData.Visual)

        if (abrasiveData.Granulometry !== undefined)
            abrasiveData.Granulometry = getSafeRelatoryCategory(abrasiveData.Granulometry)

        if (abrasiveData.Salts !== undefined)
            abrasiveData.Salts = getSafeRelatoryCategory(abrasiveData.Salts)
        
        const results = [
            abrasiveData.Visual.result.trim() === lang.approved[currLang].trim(),
            abrasiveData.Granulometry.result.trim() === lang.approved[currLang].trim(),
            abrasiveData.Salts.result.trim() === lang.approved[currLang].trim()
        ];

        const finalResult = results.every((result) => result);

        // Ink

        const combinedData = {
            InspectionChars: [] as any[],
            InkEnd: [] as any[]
        };
        
        relatory.Ink.forEach((relatory: any, index: any) => {
            combinedData.InspectionChars[index] = relatory.InspectionChars;
            combinedData.InkEnd[index] = relatory.InkEnd;
        });
        
        const inkData = {
            ...relatory.Ink,
            InspectionChars: combinedData.InspectionChars,
            InkEnd: combinedData.InkEnd
        }

        if (combinedData.InspectionChars !== undefined)
            combinedData.InspectionChars = getSafeRelatoryCategory(combinedData.InspectionChars, 'Ink')

        if (combinedData.InkEnd !== undefined)
            combinedData.InkEnd = getSafeRelatoryCategory(combinedData.InkEnd, 'Ink')

        // Temperature

        let climate: any = []
        relatory.Climate.forEach((data: any,index: any) => {
            climate[index] = getSafeRelatoryCategory(data, 'Climate')
        })
        
        const climateData = {
            ...relatory.Climate,
            climate: climate,
        }

        // RIP

        const RIPData = {
            ...relatory.RIP,
            GeneralConditions: relatory.RIP.GeneralConditions,
            AppInspection: relatory.RIP.AppInspection,
            WetFilm: relatory.RIP.WetFilm,
            Tags: relatory.RIP.Tags,
            PaintMeasures: relatory.RIP.PaintMeasures,
            Roughness: relatory.RIP.Roughness,
        }

        // General

        let locale = 'en-US'
        if (currLang === 'PT')
            locale = 'pt-BR'
        else if (currLang === 'ES')
            locale = 'es-ES'

        const lastDate = inspection.last_modified_at.split(' ')[0]
        const lastFormattedDate = new Date(lastDate).toLocaleDateString(locale)

        const companyLogo = await urlImageToBase64(companyImg)
        const surplusLogo = await urlImageToBase64(surplusFullLogo)

        const generalData = {
            ...inspection,
            plant: plant,
            company: company,
            process: process,
            equipment: equipment,
            inspector: stored.inspector,
            last_modified_at: lastFormattedDate,
            currLang: currLang,
            companyLogo: companyLogo,
            surplusLogo: surplusLogo,
            finalResult: finalResult,
        }

        // Export

        sessionStorage.setItem('generalData', JSON.stringify(generalData))
        sessionStorage.setItem('inspectionData', selectedCategories.includes('Abrasive') ? JSON.stringify(abrasiveData) : selectedCategories.includes('Climate') ? JSON.stringify(climateData) : selectedCategories.includes('RIP') ? JSON.stringify(RIPData) : JSON.stringify(inkData))
    }

    async function exportNR13Data() {
        // NR13

        const inspectionData = {
            ...relatory,
        }

        // General

        let locale = 'en-US'
        if (currLang === 'PT')
            locale = 'pt-BR'
        else if (currLang === 'ES')
            locale = 'es-ES'

        const lastDate = inspection.last_modified_at.split(' ')[0]
        const lastFormattedDate = new Date(lastDate).toLocaleDateString(locale)

        const companyLogo = await urlImageToBase64(companyImg)
        const surplusLogo = await urlImageToBase64(surplusFullLogo)

        const equipmentData = JSON.parse(fullEquipment.technical_data)

        //const external_imgs = []
        //const internal_imgs = []
        
        /*for (let img of images) {
            if (img.includes("extern")) {
                const base64 = await urlImageToBase64(img);
                external_imgs.push(base64);
            } else if (img.includes("intern")) {
                const base64 = await urlImageToBase64(img);
                internal_imgs.push(base64);
            }
        }*/

        const generalData = {
            ...inspection,
            plant: plant,
            company: company,
            process: process,
            equipment: equipment,
            inspector: stored.inspector,
            last_modified_at: lastFormattedDate,
            currLang: currLang,
            companyLogo: companyLogo,
            surplusLogo: surplusLogo,
            equipmentData: equipmentData,
        }

        /*const imageData = {
            internal_imgs: internal_imgs,
            external_imgs: external_imgs,
        }*/

        // Export

        sessionStorage.setItem('generalData', JSON.stringify(generalData))
        sessionStorage.setItem('inspectionData', JSON.stringify(inspectionData))
        //sessionStorage.setItem('imageData', JSON.stringify(imageData))
    }

    function renderData(storedData: any, relatoryData: any) {
        if (isEdit)
            return {
                deadline: storedData.deadline ? storedData.deadline : '???',
                start_date: storedData.start_date ? storedData.start_date : '???',
                company: storedData.company ? storedData.company : '???',
                plant: storedData.plant ? storedData.plant : '???',
                process: storedData.process ? storedData.process : '???',
                userName: storedData.userName ? storedData.userName : '???',
                equipment: storedData.equipment ? storedData.equipment : '???',
                inspector: storedData.inspector ? storedData.inspector : '???',
            }
        
        return {
            deadline: storedData.deadline ? storedData.deadline : '???',
            start_date: storedData.start_date ? storedData.start_date : '???',
            company: storedData.company ? storedData.company : '???',
            plant: storedData.plant ? storedData.plant : '???',
            process: storedData.process ? storedData.process : '???',
            userName: storedData.userName ? storedData.userName : '???',
            equipment: storedData.equipment ? storedData.equipment : '???',
            RepairData: relatoryData.RepairData ? relatoryData.RepairData : 'N/A',
            last_modified_at: stored.last_modified_at ? stored.last_modified_at : 'N/A',
        }
    }

    async function handlePaintingPopup() {
        setCategories(Object.keys(JSON.parse(stored.relatory)))
        //setSelectedCategories(selectedCategory ? typeof selectedCategory == 'number' || selectedCategory === '0' ? ['Ink'] : selectedCategory : Object.keys(JSON.parse(stored.relatory)))
        setSelectedCategories([])
        setPaintingPopup(true)
    }

    async function handleOpenPDFPopup() {
        if(showPDFPopup)
            return setShowPDFPopup(false)

        if(stored.type == 'p' && !paintingPopup)
            return handlePaintingPopup()
        if (selectedCategories.length > 1)
            return setMultipleSelected(true)

        paintingPopup ? setIsLoading(true) : setExportLoading(true)
        stored.type == 'p' ? await exportPaintingData() :  stored.type == 'nr13' ? await exportNR13Data() : await exportCorrosionData()
        await sleep(2000)
        setShowPDFPopup(true);
        paintingPopup ? setIsLoading(false) : setExportLoading(false)
    };

    /*async function handleXLSDownload() {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("DadosDeInspecao");

        const safeString = (value: any): string => {
            if (value === null || value === undefined) {
                return 'N/A';
            }
            return value.toString();
        };

        const addImageToPDF = async () => {
            const response = await fetch(logoImage);
            const blob = await response.blob();

            return new Promise<string>((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result as string);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        };

        const base64Image = await addImageToPDF();

        const imageId = workbook.addImage({
            base64: base64Image,
            extension: 'png',
        });

        const imageId2 = workbook.addImage({
            base64: companyImg,
            extension: 'png',
        });

        const imageRow = 3;
        worksheet.addImage(imageId, {
            tl: { col: 0.1, row: 0.4 },
            ext: { width: 150, height: 30 },
            editAs: 'oneCell'
        });

        worksheet.addImage(imageId2, {
            tl: { col: 1.99, row: 0.1 },
            ext: { width: 120, height: 50 },
            editAs: 'oneCell'
        });

        const startRow = imageRow + 2;

        const alturaLinhaPadrao = 20;
        const larguraColuna1 = 25;
        const larguraColuna2 = 45;

        const fontePadrao = {
            name: 'Arial',
            size: 12
        };

        const estiloBorda: BorderStyle = 'thin'; // Especifica o estilo como BorderStyle

        const bordaPadrao = {
            top: { style: estiloBorda, color: { argb: 'FF000000' } },
            bottom: { style: estiloBorda, color: { argb: 'FF000000' } },
            left: { style: estiloBorda, color: { argb: 'FF000000' } },
            right: { style: estiloBorda, color: { argb: 'FF000000' } },
        };

        data.forEach((row: any, rowIndex: any) => {
            // Aplica o filtro safeString a cada valor da linha
            const safeRow = row.map((cell: any) => safeString(cell));

            // Insere a linha modificada na planilha
            const newRow = worksheet.insertRow(rowIndex + startRow, safeRow);

            // (Opcional) Aplica estilos à nova linha, se necessário
            newRow.eachCell(cell => {
                cell.font = fontePadrao;
                cell.border = bordaPadrao;
            });
        });

        // Definindo a altura padrão para todas as linhas
        worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
            row.height = alturaLinhaPadrao;
        });

        // Definindo largura específica para a primeira e a segunda coluna
        worksheet.getColumn(1).width = larguraColuna1;
        worksheet.getColumn(2).width = larguraColuna2;

        worksheet.mergeCells('A1:A2');
        worksheet.mergeCells('B1:B2');
        worksheet.mergeCells('A3:B3');

        worksheet.getCell('A3').value = 'RELATÓRIO DE INSPEÇÃO';
        worksheet.getCell('A4').value = 'Campo';
        worksheet.getCell('B4').value = 'Valor';

        worksheet.getCell('A3').alignment = { horizontal: 'center', vertical: 'middle' };

        worksheet.getCell('A3').font = {
            name: 'Arial',
            size: 14,
            bold: true,
        };
        worksheet.getCell('A4').font = {
            name: 'Arial',
            size: 12,
            bold: true,
        };
        worksheet.getCell('B4').font = {
            name: 'Arial',
            size: 12,
            bold: true,
        };

        worksheet.getCell('A1').border = {
            top: { style: 'thin', color: { argb: 'FF000000' } },
            left: { style: 'thin', color: { argb: 'FF000000' } },
        };
        worksheet.getCell('B1').border = {
            top: { style: 'thin', color: { argb: 'FF000000' } },
            right: { style: 'thin', color: { argb: 'FF000000' } },
        };
        worksheet.getCell('A3').border = {
            bottom: { style: 'thin', color: { argb: 'FF000000' } },
            left: { style: 'thin', color: { argb: 'FF000000' } },
            right: { style: 'thin', color: { argb: 'FF000000' } },
        };
        worksheet.getCell('A4').border = {
            top: { style: 'thin', color: { argb: 'FF000000' } },
            bottom: { style: 'thin', color: { argb: 'FF000000' } },
            left: { style: 'thin', color: { argb: 'FF000000' } },
            right: { style: 'thin', color: { argb: 'FF000000' } },
        };
        worksheet.getCell('B4').border = {
            top: { style: 'thin', color: { argb: 'FF000000' } },
            bottom: { style: 'thin', color: { argb: 'FF000000' } },
            left: { style: 'thin', color: { argb: 'FF000000' } },
            right: { style: 'thin', color: { argb: 'FF000000' } },
        };

        // Exportando o arquivo
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'dados_de_inspecao.xlsx';
        a.click();
        URL.revokeObjectURL(url);
    }*/

    async function handleApprove() {
        const inspectionID = await getDataBaseCondition('inspections', 'company', companyID)
        const count = inspectionID.filter((it: any) => it.approved == 1).length + 1

        const send = { 
            is_checked: 1,
            is_draft: 0,
            approved: 1,
            approved_date: '',
            last_modified_at: '',
            user_id: stored.user_id,
            approved_by: user.name,
            relatory_id: count,
        }

        const inspectionResponse = await editDatabaseByCondition('inspections', 'id', stored.id, send)
        const inspectorResponse = await getDataBaseCondition('users', 'id', stored.user_id)

        if (!inspectionResponse || inspectionResponse.status != 'success')
            return alert('erro ao se comunicar com o banco de dados')

        if (!inspectorResponse.length)
            return alert('User not found')

        const inspector = inspectorResponse[0]
        const inspectorLang = inspector.lang
        const translation = getNormalizedInspectorLang(inspectorLang)

        await sendNotification({
            itemId: stored.id,
			userId: stored.user_id,
			type: NotificationTypes.NOTIFY_INSPECTION_APPROVED,
			title: lang.notification.title[translation],
			msg: lang.notification.content[translation].replace('{{inspectionTitle}}', stored.title),
			data: {
				inspectionTitle: stored.title
			}
        })

        return navigate('/Home')
    }

    function handleRetPopUp() {
        setIsRet(true)
        setShowRetPopup(true)
    }

    function handleCloseModal() {
        setIsRet(false)
        setShowRetPopup(false)
        setPaintingPopup(false)
        setShowPDFPopup(false)
    }

    function handleNextDiv(item: any) {
        setSelectedCategory(item)
    }

    function renderOption(key: string, value: any, line: boolean = true, opacity: number = 0.8, isDiv?: boolean) {
        let isret = false
        let isMesh = false
        let isObject = false
        let isRIP = false
        if (value == '{"opt":null}')
            return 'NA'
        console.log('Key: ' + JSON.stringify(key))
        console.log('Value: ' + JSON.stringify(value))
        if (key == 'Meshes Retentions' || key == 'Retenções de Malhas' || key == 'Retenciones de Mallas')
            isMesh = true

        if (key == 'Rectifications' || key == 'Retificações' || key == 'Retificaciones')
            isret = true

        if (typeof value === 'object')
            isObject = true

        if (selectedCategory === 'RIP')
            isRIP = true

        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} key={key}>
                {!line && (<div style={{ padding: '.2rem' }} />)}
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '98%' }}>
                    {
                        isDiv ?
                        <p style={{ fontSize: '1.1rem', opacity: opacity, color: 'white' }}>{key == 'relatory' ? `${lang[key][currLang]} #${value + 1}` : lang[key][currLang]}</p>
                        :
                        <p style={{ fontSize: '1.1rem', opacity: opacity, color: 'white' }}>{key}</p>
                    }
                    
                    {
                        isret ? 
                        <FontAwesomeIcon icon={faAngleRight} onClick={() => handleRetPopUp()} className="angleRightOption" style={{opacity: opacity}} />
                        :
                        isDiv ? 
                        <FontAwesomeIcon icon={faAngleRight} onClick={() => key == 'relatory' ? handleNextDiv(value) : handleNextDiv(key)} className="angleRightOption" style={{opacity: opacity}} />
                        :
                        isMesh ?
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {value.map((item: any, index: number) => (
                                    <p 
                                        key={index} 
                                        style={{ fontSize: '1.1rem', opacity: opacity, color: 'white', maxWidth: '40vw' }}>
                                        {lang.mesh[currLang]} {index + 1}: {item} %
                                    </p>
                            ))}
                        </div>
                        :
                        isObject ?
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {Object.entries(value).map(([itemName, itemValue]: [string, any]) => (
                                <>
                                <p 
                                    style={{ fontSize: '1.1rem', opacity: opacity, color: 'white', maxWidth: '40vw' }}>
                                    {lang[itemName][currLang]}: {itemValue == true ? 'x' : itemValue == false ? '' : itemValue} {isRIP ? '' : itemValue == true ? '' : itemValue == false ? '' : '%'}
                                </p>
                                </>
                                
                            ))}
                        </div>
                        :
                        <p style={{ fontSize: '1.1rem', opacity: opacity, color: 'white', maxWidth: '40vw' }}>{value}</p>
                    }
                    
                </div>

                <div style={{ padding: '.2rem' }} />

                {line && (<div className="line" style={{ opacity: opacity }} />)}
                {line && (<div style={{ padding: '.2rem' }} />)}
            </div>
        )
    }

    function handleOptionClick (categoryName: string) {
        setSelectedDay(prevCategory => prevCategory === categoryName ? null : categoryName);
    };

    function renderSelectionableOption(key: string, value: any, line: boolean = true, opacity: number = 0.8, isDiv?: boolean) {
        let isObject = false

        if (typeof value === 'object')
            isObject = true

        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} key={key}>
                {!line && (<div style={{ padding: '.2rem' }} />)}
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '98%' }}  onClick={() => isDiv ? handleOptionClick(value) : null}>
                    {   isDiv?
                            <p style={{ fontSize: '1.1rem', opacity: opacity, color: 'white' }}>{key} {value}</p>
                        :
                            <p style={{ fontSize: '1.1rem', opacity: opacity, color: 'white', paddingLeft: '20px' }}>{key}</p>
                    }
                    
                    {   
                        isObject ?
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                {Object.entries(value).map(([itemName, itemValue]: [string, any]) => (
                                    <p 
                                        style={{ fontSize: '1.1rem', opacity: opacity, color: 'white', maxWidth: '40vw' }}>
                                        {lang[itemName][currLang]}: {itemValue} {itemName == 'humidity' ? '%' : 'ºC'}
                                    </p>                                    
                                ))}
                            </div>
                        :
                        isDiv ?
                            selectedDay === value ?
                                <FontAwesomeIcon icon={faAngleUp} className="angleRightOption" style={{opacity: opacity}} />
                            :
                                <FontAwesomeIcon icon={faAngleDown} className="angleRightOption" style={{opacity: opacity}} />
                        :
                            <p style={{ fontSize: '1.1rem', opacity: opacity, color: 'white', maxWidth: '40vw', paddingRight: '20px'}}>{value}</p>
                    }
                    
                </div>

                <div style={{ padding: '.2rem' }} />

                {line && isDiv ? (<div className="line" style={{ opacity: opacity }} />) : (<div className="line" style={{ opacity: opacity, marginLeft: '20px', marginRight: '40px' }} />)}
                {line && (<div style={{ padding: '.2rem' }} />)}
            </div>
        )
    }

    const getImageName = (url: string) => {
        if(stored.type != 'NR13')
            return ''
        let imgTitle = url.substring(url.lastIndexOf('/') + 1).trim();
        let imgsDesc = JSON.parse(imagesDesc)

        let imgDesc = imgsDesc[imgTitle];
      
        if (imgDesc)
          return imgDesc;
        else 
          return 'NA';
      };
      

    function renderPhotos() {
        let block = []

        for (let x = 0; x < images.length; x = x + 4)
            block.push(
                <div key={x} style={{ display: 'flex', marginBottom: '1rem' }}>
                    {(x + 0 < images.length) &&
                        <div className="photoContainer">
                            {renderCameraIcon('100%', '10rem', images[x])}
                            <p className="photoTitle">{getImageName(images[x])}</p>
                        </div>
                    }

                    <div style={{ width: '6.666%' }} />

                    {(x + 1 < images.length) &&
                        <div className="photoContainer">
                            {renderCameraIcon('100%', '10rem', images[x + 1])}

                            <p className="photoTitle">{getImageName(images[x + 1])}</p>
                        </div>
                    }

                    <div style={{ width: '6.666%' }} />

                    {(x + 2 < images.length) &&
                        <div className="photoContainer">
                            {renderCameraIcon('100%', '10rem', images[x + 2])}

                            <p className="photoTitle">{getImageName(images[x + 2])}</p>
                        </div>
                    }

                    <div style={{ width: '6.666%' }} />

                    {(x + 3 < images.length) &&
                        <div className="photoContainer">
                            {renderCameraIcon('100%', '10rem', images[x + 3])}

                            <p className="photoTitle">{getImageName(images[x + 3])}</p>
                        </div>
                    }
                </div>
            )

        return block
    }

    function handleType() {
        if (stored.type == 'p')
            return lang.painting[currLang]
        if (stored.type == 'c')
            return lang.corrosionType[currLang]
        if (stored.type == 'nr13')
            return lang.NR13[currLang]

        return 'N/A'
    }

    function handleOptions(item: any) {
        let sanitizedRelatory = {...relatory}
        
        if (typeof item === 'number')
            sanitizedRelatory = sanitizedRelatory.Ink[item]
        else
            sanitizedRelatory = sanitizedRelatory[item]
        //sanitizedRelatory = sanitizedRelatory.WetFilm
        //alert(JSON.stringify(sanitizedRelatory))
        if (item === 'Climate') {
            return (
                Object.entries(sanitizedRelatory).map(([categoryName, categoryRelatory]: any) => {
                    return (
                        <div key={categoryName}>
                            {renderSelectionableOption(lang.day[currLang], categoryRelatory.day, true, 0.9, true) /*Nome da categoria*/}
                            {   
                                selectedDay === categoryRelatory.day && (
                                    !Array.isArray(categoryRelatory) &&
                                    Object.entries(categoryRelatory).map(([fieldName, fieldValue]: any) => {
                                        if(fieldName !== 'day' && fieldName !== '_editTimestamp'){
                                            if (typeof fieldValue === 'object'){
                                                let subOptions = fieldValue;
                                                fieldValue = fieldValue[currLang];
                                                
                                                if(!fieldValue)
                                                    fieldValue = subOptions;
                                            }

                                            if (lang[fieldName] !== undefined)
                                                return renderSelectionableOption(lang[fieldName][currLang], fieldValue, true, 0.8, false);
                                            else
                                                console.log('');
                                        } else {
                                            console.log('');
                                        }
                                    })
                                )
                            }
                        </div>
                    );
                })
            );
        } 

        return (
            Object.entries(sanitizedRelatory).map(([categoryName, categoryRelatory]: any) => {
                //alert(JSON.stringify(categoryName))
                //alert(JSON.stringify(categoryRelatory))
                //alert(JSON.stringify(sanitizedRelatory.PaintMeasures))
                if (categoryName == 'Tags' || categoryName == 'PaintMeasures')
                    return null
                return (
                    <div key={categoryName}>
                        {renderOption(lang[categoryName][currLang], '', false, 1) /*Nome da categoria*/}
                        {
                            !Array.isArray(categoryRelatory) &&
                            Object.entries(categoryRelatory).map(([fieldName, fieldValue]: any) => {
                                if (typeof fieldValue === 'object' && fieldName != 'meshesRetentions' && item != 'RIP')
                                    fieldValue = fieldValue[currLang]
                                if (lang[fieldName] !== undefined)
                                    return renderOption(lang[fieldName][currLang], fieldValue)
                            
                                else
                                    console.log(fieldName)
                            })
                        }
                    </div>
                )
            })
        )
    }

    function renderActive() {
        if (isEdit)
            return (
                <div>
                    {renderOption(lang.startDate[currLang], formatTimestamp(inspection.start_date))}
                    {renderOption(lang.deadline[currLang], formatTimestamp(inspection.deadline))}
                    {renderOption(lang.company[currLang], company)}
                    {renderOption(lang.plant[currLang], plant)}
                    {renderOption(lang.process[currLang], process)}
                    {renderOption(lang.inspectorName[currLang], inspection.inspector)}
                    {renderOption(lang.equipment[currLang], equipment)}
                </div>
            );

        if (selectedOption === 'visualInspection')
            return (
                <div>
                    {renderOption(lang.startDate[currLang], formatTimestamp(inspection.start_date))}
                    {renderOption(lang.deadline[currLang], formatTimestamp(inspection.deadline))}
                    {renderOption(lang.company[currLang], company)}
                    {renderOption(lang.plant[currLang], plant)}
                    {renderOption(lang.process[currLang], process)}
                    {renderOption(lang.equipment[currLang], equipment)}
                    {renderOption(lang.inspectorName[currLang], stored.inspector)}
                    {renderOption(lang.type[currLang], handleType())}
                    {renderOption(lang.lastInspection[currLang], formatTimestamp(inspection.last_modified_at))}
                    {renderOption(lang.rectifications[currLang], relatory?.retifications ? relatory?.retifications : 'N/A')}
                </div>
            )

        if (selectedOption === 'relatory') {
            /*
                O objeto relatório possui o seguinte formato:
                {
                    Categoria1: {
                        campo1: valor1,
                        campo2: valor2,
                        ...
                    }
                    ...
                }

                ---------------------------------------------------

                Dentro de cada categoria o objeto pode ter 2 formas:

                - chave: `valor`
                - chave: { PT: `valor em português`, US: `valor em inglês` , ES: `valor em espanhol` }
            */
            let sanitizedRelatory = {...relatory}
            //alert(JSON.stringify(sanitizedRelatory))
            if (stored.type == 'p') {  
                return (
                    <>  
                        {selectedCategory || selectedCategory == '0' ? (
                            selectedCategory === 'Ink' ? (
                                <>
                                {console.log('sem o delay desse console.log as opçoes se atropelam. arranjar soluçao efetiva')}
                                {Object.entries(sanitizedRelatory.Ink).map((relatorio: any, index: number) => (
                                    renderOption('relatory', index, true, 1, true) // Nome da categoria
                                ))}
                                </>
                            ) : (
                                handleOptions(selectedCategory)
                            )
                        ) : (
                            Object.entries(sanitizedRelatory).map(([categoryName]: any) => (
                                <div key={categoryName}>
                                    {renderOption(categoryName, '', true, 1, true) /*Nome da categoria*/}
                                </div>
                            ))
                        )}
                    </>
                )
            }
        
            if (stored.type == 'c'){
                delete sanitizedRelatory['retifications']
                delete sanitizedRelatory['Recommendations']['time']
            }
            let support: any
            return (
                Object.entries(sanitizedRelatory).map(([categoryName, categoryRelatory]: any) => {
                    return (
                        <div key={categoryName}>
                            {renderOption(lang[categoryName][currLang], '', false, 1) /*Nome da categoria*/}
                            {
                                // Mostra os campos e seus valores dentro da categoria atual
                                !Array.isArray(categoryRelatory) &&
                                Object.entries(categoryRelatory).map(([fieldName, fieldValue]: any) => {
                                    if (typeof fieldValue === 'object'){
                                        support = fieldValue
                                        fieldValue = fieldValue[currLang]
                                    }   

                                    if (lang[fieldName] !== undefined)
                                        return renderOption(lang[fieldName][currLang], fieldValue ? fieldValue : support)
                                    else
                                        console.log(fieldName)
                                }) ||
                                categoryRelatory.map((item: any, index: number) => (
                                    <div key={index}>
                                        <p style={{ fontSize: '18px', color: '#fafafa' }}>
                                            {item.itemDescription}
                                        </p>
                                        {renderOption(lang.dimensionPol[currLang], item.dimensionPol)}
                                        {renderOption(lang.dimensionMeter[currLang], item.dimensionMeter)}
                                        {renderOption(lang.inputWidth[currLang], item.inputWidth)}
                                        {renderOption(lang.inputLength[currLang], item.inputLength)}
                                        {renderOption(lang.quantity[currLang], item.quantity)}
                                        {renderOption(lang.totalArea[currLang], item.totalArea)}
                                    </div>
                                ))
                            }
                        </div>
                    )
                })
            )
        }

        if (selectedOption === 'photos')
            return renderPhotos();


        if (selectedOption === 'recomendations')
            return (
                <div>
                    {renderOption(lang.points[currLang], relatory?.Recommendations?.points)}
                    {renderOption(lang.repairType[currLang], relatory?.Recommendations?.repairType)}
                    {renderOption(lang.access[currLang], relatory?.Recommendations?.access)}
                    {renderOption(lang.repairHeight[currLang], relatory?.Recommendations?.repairHeight)}
                </div>
            )

        return (<div />)
    }

    function renderTitleOptions() {
        if (isEdit)
            return (
                <div className="ExploreRow">
                    <p
                        className="optionText"
                        onClick={() => handleSelectedOption('visualInspection')}
                        style={{ color: selectedOption == 'visualInspection' ? '#f36934' : 'white' }}
                    >
                        {lang.generalInformation[currLang]}
                    </p>
                </div>
            )

        return (
            <div className="ExploreRow">
                <div />

                <p
                    className="optionText"
                    onClick={() => handleSelectedOption('visualInspection')}
                    style={{ color: selectedOption == 'visualInspection' ? '#f36934' : 'white' }}
                >
                    {lang.overview[currLang]}
                </p>

                <div />

                <p
                    className="optionText"
                    onClick={() => handleSelectedOption('relatory')}
                    style={{ color: selectedOption == 'relatory' ? '#f36934' : 'white' }}
                >
                    {lang.report[currLang]}
                </p>

                <div />

                <p
                    className="optionText"
                    onClick={() => handleSelectedOption('photos')}
                    style={{ color: selectedOption == 'photos' ? '#f36934' : 'white' }}
                >
                    {lang.photos[currLang]}
                </p>

                <div />
            </div>
        )
    }

    async function handleEdit() {
        saveJsonData('edit', {
            ...stored,
            userName: stored.inspector
        })

        return navigate('/Request?edit')
    }

    function formatDate(timestamp: any) {
        const date = new Date(timestamp);
        const day = date.getDate().toString().padStart(2, '0'); 
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    async function changeReportRetification(){
        if(retText?.trim()?.length == 0)
            return alert('texto inválido')
        
        const date = new Date(Date.now());
        const retificationText = `${user.name} - ${formatDate(date)}: \n${retText}`;

        let newRetificationText = relatory.retifications ? `${relatory.retifications}\n${retificationText}\n` : `${retificationText}\n`;
        
        const newData = {
            is_checked: 1,
            is_draft: 0,
            approved: 1,
            approved_date: '',
            last_modified_at: '',
            user_id: stored.user_id,

            relatory: JSON.stringify({
                ...relatory, 
                retifications: newRetificationText
            })
        }
        
        const response = await editDatabaseByCondition('inspections', 'id', stored.id, newData)
        await sleep(500)

        if(!response || response.status != 'success')
            return alert('erro ao enviar retificação')
        
        await sleep(500)
        const newStored = await getDataBaseCondition('inspections', 'id', stored.id)
        
        saveJsonData('pendingInspection', newStored[0])
        window.location.reload();
    }

    function renderRelatory() {
        let sltTemplate
        if (stored.type == 'p')
            sltTemplate = `template${selectedCategories}/index.html`
        else if (stored.type == 'c')
            sltTemplate = 'template/index.html'
        else if (stored.type == 'nr13')
            sltTemplate = 'templateNR13/index.html'

        return (
            <div className="popup" style={{boxShadow: '0 0 30px rgba(0, 0, 0, 0.5)', display: "none"}}>
                <iframe
                    src={sltTemplate}
                    style={{ 
                        width: '100%', 
                        height: '40px',  
                        margin:  0,
                        padding: 0,
                        border:  0
                    }}
                    title="Pré-visualização do PDF"
                ></iframe>

                {/*<div 
                    className="optButtons_Explore"
                >
                    <button 
                        className="closeButton" 
                        onClick={handleXLSDownload} 
                        disabled 
                        style={{ 
                            backgroundColor: '#2f3236',
                            borderRadius: 0,
                            height: '40px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            paddingRight: '25%',
                            paddingLeft: '25%'
                        }}
                    >
                        EXCEL 
                        {<FontAwesomeIcon icon={faFileExcel}/>}
                    </button>
                </div>*/}
            </div>
        )
    }

    function renderExport() {
        if(exportLoading)
            return (
                <div className="approveButton" style={{height: '2.3rem'}}>
                    <div className="loadingCircle"/>
                </div>
            )


        return (
            <div style={{ display: "flex", alignItems: 'center' }}>
                {
                    isEdit ?
                    (<button className="approveButton" onClick={async () => await handleEdit()}>{lang.edit[currLang]}</button>) :
                    
                    stored.approved == 1 ?
                    (
                        <div>
                            <button 
                                className="approveButton" 
                                onClick={async () => await handleOpenPDFPopup()}
                                disabled={isLoading || !exportButton}
                                id='export-button'
                            >
                                {
                                    isSmallSizeWindow ? <FontAwesomeIcon icon={faFileExport}/> : lang.export[currLang]
                                }
                            </button>

                            {
                                showPDFPopup && !paintingPopup && (
                                    renderRelatory()
                                )
                            }
                        </div>
                    ) :
                    (
                        <AsyncButton
                            className="approveButton"
                            onClick={async () => {
                                setLoadingApprove(true);
                                await sleep(500);
                                await handleApprove();
                                setLoadingApprove(false);
                            }}
                            text={lang.approve[currLang]}
                            loading={loadingApprove}
                            active={loadingApprove}
                        />
                        
                    )
                }
            </div>
        )
    }

    function handleCheckboxChange(item: any) {
        setSelectedCategories(prevSelectedCategories => {
            if (prevSelectedCategories.includes(item)) 
                return prevSelectedCategories.filter(name => name !== item);
            else
                return [...prevSelectedCategories, item];
          });
          setShowPDFPopup(false)
    }

    return (
        <div className="MainContainer">
            <FlagSelector />
            <Sidebar activePage='Explore' />
            
            <CustomModal modalOpen={showRetPopup} interfere={false} className='RetPopupContainer'>
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                    <div className='dateText' style={{marginLeft: '0px'}}>
                    {lang.inspectionRectification[currLang]}
                    </div>
                    
                    <FontAwesomeIcon
                        height='100%' 
                        icon={faX} 
                        color='white' 
                        cursor='pointer'
                        onClick={() => handleCloseModal()}
                    />
                </div>

                <div style={{padding: '1rem'}}/>

                <div className='dateText' style={{fontSize: '1rem', marginLeft: '0px'}}>
                    {lang.comments[currLang]}
                </div>

                <div style={{padding: '0.5rem'}}/>

                <textarea className="input" value={isRet ? relatory.retifications : retText} onChange={(event: any) => setRetText(event.target.value)} readOnly={isRet}/>

                <div style={{padding: '0.5rem'}}/>
                {!isRet && (
                    <AsyncButton
                        className='RetPopupButton'
                        active={isLoading}
                        text='Enviar'
                        onClick={async () => {
                            setIsLoading(true)
                            await sleep(500)
                            await changeReportRetification()
                            setIsLoading(false)
                            setShowRetPopup(false)
                        }}
                        loading={isLoading}
                    />
                    )
                }
                
            </CustomModal>

            <CustomModal modalOpen={paintingPopup} interfere={false} className='PaintingPopupContainer'>
                <div style={{ position: 'relative', width: '100%', padding: '0 20px' }}>
                    <div className='dateText' style={{ textAlign: 'center'}}>
                        {lang.export[currLang]}
                    </div>
                    <div style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}>
                        <FontAwesomeIcon
                            height='100%' 
                            icon={faX} 
                            color='white' 
                            cursor='pointer'
                            onClick={() => handleCloseModal()}
                        />
                    </div>
                </div>


                <div style={{padding: '5%'}}/>

                <div className="PaintingPopupCheckboxContainer">
                    {categories.map((categoryName) => (
                    <div key={categoryName} >
                        <label className="checkbox-container">
                        <input type="checkbox" 
                            checked={selectedCategories.includes(categoryName)}
                            onChange={() => handleCheckboxChange(categoryName)}
                        />
                        {lang[categoryName][currLang]}
                        </label>
                    </div>
                    ))}
                </div>

                {selectedCategories.length > 1 ?  
                    <div style={{paddingBottom: '5%', color: 'red'}}>{lang.selectOnlyOneReport[currLang]}</div>
                    : 
                    <div style={{padding: '5%'}}/>
                }

                <AsyncButton
                    className='PaintingPopupButton'
                    active={isLoading}
                    text={lang.select[currLang]}
                    onClick={async () => {
                        handleOpenPDFPopup()
                    }}
                    loading={isLoading}
                />
                
                {showPDFPopup && paintingPopup && renderRelatory()}

            </CustomModal>

            <div className='SideBarCompensation' />

            <div className="CentralBlock">
                <div className="ExploreInfoContainer">
                    <div className="ExploreRow">
                        <div id="exploreTitleContainer">
                            <button onClick={() => navigate(-1)}>
                                <FontAwesomeIcon icon={faAngleLeft} className="backIcon" />
                            </button>

                            <p className="dateText">
                                {(stored?.title?.length <= 55) ? stored.title : stored?.title?.substring(0, 55) + '...'}
                            </p>
                        </div>
                        
                        <div style={{display: 'flex'}}>
                            <button 
                                className="approveButton" 
                                onClick={() => setShowRetPopup(true)}
                                disabled={isLoading}
                                id='export-button'
                                style={{
                                    marginRight: '1rem', 
                                    ...(stored.approved == 1 ? {} : {backgroundColor: 'transparent', color: 'transparent'})
                                }}
                            >
                                {lang.rectify[currLang]}
                            </button>

                            {renderExport()}
                        </div>
                        
                    </div>

                    {renderTitleOptions()}

                    <div className="line" />
                    <div style={{ padding: '.5rem' }} />

                    <ScrollView className="scrollViewExplore">
                        {renderActive()}
                    </ScrollView>
                </div>
            </div>
        </div>
    )
}